import FikusLirata from "./BigSize/فیکوس لیراتا (2).png"
import FikusElastik from "./BigSize/فیکوس الاستیک.png"
import FikusAltesima from "./BigSize/فیکوس آلتیسیما.png"
import Bonsay from "./BigSize/بونسای میکروکارپا.png"
import FikusAmestel from "./BigSize/فیکوس آمستل.png"
import Putos from "./BigSize/پوتوس برگ پهن.png"
import Linda from "./BigSize/لیندا.png"
import Eforbia from "./BigSize/افوربیبا.png"
import EforbiaGhermez from "./BigSize/افوربیا.png"
import SansoriaPakuta from "./BigSize/سانسوریا-پاکوتاه.png"
import Yuka from "./BigSize/یوکا.png"
import Sonsuria from "./BigSize/سانسویا.png"
import Shefelra from "./BigSize/شفلرا.png"
import babaAdam from "./BigSize/بابا آدم.png"
////// 
import Anturiom from "./goldar/آنتوریوم_قرمز.png"
import Orkid from "./goldar/ارکید.png"
import Bent from "./goldar/بنت_قنسول.png"
import Sarakhs from "./goldar/سرخس_لانه_پرنده.png"
import Shamdani from "./goldar/شمعدانی.png"
import Sheypori from "./goldar/شیپوری.png"
import Ghashogi from "./goldar/قاشقی.png"
import Naz from "./goldar/ناز.png"
import Viritz from "./goldar/ویریز_قرمز.png"
import Ikima from "./goldar/ایکمیا_فاسکیاتا.png"
//////
import Pupak from "./terarioum/تراریوم_پوپک.png"
import Geo from "./terarioum/تراریوم_جئومتریک.png"
import Jensing from "./terarioum/تراریوم_بونسای_جنسینگ.png"
//////
import Kerasula from "./kaktous/کراسولا.png"
import Khargushi from "./kaktous/کاکتوس_گوش_خرگوشی.png"
import Sakhre from "./kaktous/کاکتوس صخره ای.png"
import Echinu from "./kaktous/کاکتوس اچینو.png"
import Inermes from "./kaktous/افوربیا_اینرمیس.png"
import Victoria from "./kaktous/آگاو_ویکتوریا.png"
import Fiflera from "./kaktous/آگاو_فیفلرا_مویی.png"
///////
import Albuka from "./bedonGol/آلبوکا_اسپیرالیس.png"
import Aglunma from "./bedonGol/آگلونما.png"
import Epumia from "./bedonGol/ایپومیا.png"
import Bamboo from "./bedonGol/بامبو.png"
import BargAnjir from "./bedonGol/برگ_انجیری.png"
import Benjamin from "./bedonGol/بنجامین.png"
import Difen from "./bedonGol/دیفن_کاملیا.png"
import Zamu from "./bedonGol/زاموفیلیا.png"
import Pele from "./bedonGol/پله_موآ.png"




const plants =[
     {
    id:1,
    title: "فیکوس لیراتا",
    desc:"گل فیکوس لیراتا یک گیاه زینتی است که از خانواده موراسهای است. این گیاه اصالتاً از منطقه استوایی جنوبی آفریقا به دست آمده و به عنوان یک گیاه زینتی بسیار محبوب شناخته می­شود. شرایط نگهداری و مراقبت از فیکوس لیراتا چگونه است؟ گل فیکوس لیراتا دارای برگ‌های بلند و تیره با الگوهای خطی سفید روی سطح برگ‌ها است. این گیاه به دلیل زیبایی و ظاهر خاص خود، به عنوان یک گیاه زینتی در منازل، دفاتر و فضاهای داخلی مورد استفاده قرار می‌گیرد.",
    protaction:"گل فیکوس لیراتا یک گیاه بسیار مقاوم و با توانایی بالا در برابر شرایط نامساعد محیطی است. این گیاه تحمل خوبی نسبت به نور کم، خشکی هوا و حتی کم‌آبی دارد. بنابراین، برای افرادی مناسب است که اهمیت زیادی به نگهداری و آبیاری گیاهان خود نمی‌دهند. همچنین، گل فیکوس لیراتا دارای قدرت رشد بالایی است و می‌تواند به ارتفاع ۱۰ تا۵۰ سانتی‌متر برسد. برگ‌های زیبا و الگوهای خطی آن، این گیاه را برای استفاده در دکوراسیون داخلی و زینتی بسیار جذاب میکند",
    protactionT:"گل فیکوس لیراتا به نور متوسط تا کم نیاز دارد. بنابراین، بهتر است آن را در محلی که نور مستقیم آفتاب نیافتد، قرار دهید.",
    protactionS:"آبیاری: این گیاه تحمل خوبی نسبت به کم‌آبی دارد، بنابراین آبیاری متناسب و با فاصله‌های زمانی بین هر آبیاری مناسب است. در فصل زمستان، آبیاری را کاهش دهید و اجازه دهید خاک خشک شود",
    img:FikusLirata,
    category: "گیاه سایز بزرگ"
  },
  {
    id:50,
    title: "بابا آدم",
    desc:"گل بابا آدم گیاهی استوایی ست با برگ‌های خشن، بزرگ و افتاده. این گیاه با کاشت پیاز و غده های ریشه دار در خاک رشد می‌کند. برگ‌های غول پیکر بابا آدم ظاهری جذاب و زیبا دارند. دمبرگ ها و ساقه های این گیاه تا 60 الی 90 سانتی متر رشد می‌کنند. همچنین معمولاً ارتفاع گل بابا آدم غول پیکر به 90 تا 150 سانتی متر در داخل خانه می‌رسد اما در خارج از خانه در شرایط محیطی مناسب تا 5 متر نیز رشد می‌کند. برگ‌های بابا آدم بزرگ و عریض بوده و تیرکمانی شکل هستند. هر قدر گیاه از نور بیشتری برخوردار شود، برگ‌های آن پهن تر و مقاوم تر خواهد بود. بر روی برگ‌های بابا آدم، لکه هایی به رنگ سفید و قرمز دیده می‌شود. 15 گونه مختلف از بابا آدم وجود دارد که عموماً چندساله هستند.",
    protaction:"همانطور که پیش از این نیز گفته شد، خواستگاه گیاه بابا آدم مناطق استوایی است. این بدین معناست که بابا آدم محیط های گرم و مرطوب را برای رشد و نگهداری ترجیح می‌دهد. به همین خاطر است که در مناطق شمالی کشور که دارای آب و هوای گرم و مرطوب هستند، گیاه بابا آدم بیشترین رشد و نمو را تجربه می‌کند.با این حساب لازم است در زمان نگهداری گل بابا آدم، حال و هوای خانه را به سمت مناطق استوایی ببرید. طوریکه نور کافی بابا آدم را تأمین کرده و به روش های مختلف، رطوبت مورد نیاز آن را فراهم کنید. برای نگهداری صحیح و اصولی از گل بابا آدم، شرایط زیر را تأمین کنید.",
    protactionT:"داشتن برگ‌های پهن و عریض، بسیاری از افراد را در تأمین نور بابا آدم به اشتباه می‌اندازد. اگرچه گیاه بابا آدم از جمله گیاهان نور دوست است، اما به هیچ وجه به نور شدید و مستقیم احتیاج ندارد. قرارگیری گلدان بابا آدم در معرض نور مستقیم خورشید، موجب سوزش برگ ها و آسیب جدی به ظاهر آن می‌شود.",
    protactionS:"در مقایسه با سایر گیاهان آپارتمانی و زینتی، بابا آدم به جهت بهره مندی از ساقه های گوشتی و برگ‌های پهن، به آبیاری بیشتری احتیاج دارد. برای اینکه برگ‌های گل بابا آدم غول پیکر به خوبی رشد کند، بهتر است در طول فصل تابستان هفته ای 2 الی 3 بار به آبیاری گیاه بپردازید. به خاطر داشته باشید آبیاری زیاد در حجم های گسترده موجب خرابی گیاه، پوسیدگی ریشه، زرد شدن برگ ها و در نهایت از بین رفتن بابا آدم می‌شود. بنابراین آبیاری گل بابا آدم را تنها زمانی که خاک گلدان رو به خشکی رفت و رطوبت خود را از دست داد، انجام دهید. بهتر است در زمان آبیاری از بالا بودن خاصیت زهکشی خاک و گلدان مطمئن شوید. چرا که حبس آب در داخل ریشه ها آسیب جدی به آن ها می‌زند.",
    protactionF:"همانطور که گفته شد، گیاه بابا آدم علاقه بسیاری به محیط های مرطوب و استوایی دارد. بنابراین می‌توان نقش عامل رطوبت را در رشد بابا آدم پر رنگ تر از سایر عوامل رشد، تصور نمود. بهتر است در روزهایی که اقدام به آبیاری گیاه نمی‌کنید، از طریق تکنیک‌های افزایش رطوبت محیط گیاه مانند غبارپاشی و اسپری کردن آب بر روی گیاه رطوبت مورد نیاز او را تأمین کنید. ساخت جزیره نیز کمک شایانی به تأمین رطوبت بابا آدم می‌کند. برای اینکار کافیست در زیر گلدان بابا ادم مقداری سنگریزه ریخته و آن را با آب پر کنید. سپس گلدان را بر روی سطح سنگریزه ها قرار دهید. دقت کنید که گلدان را مستقیماً بر روی سنگریزه ها قرار دهید تا با آب گلدان تماس مستقیم نداشته باشد.",
    protactionFF:"از دیگر عواملی که هنگام نگهداری گل بابا آدم باید به آن توجه کرد؛ تعویض گلدان به موقع و انتخاب سایز مناسب آن می‌باشد. گلدان بابا آدم باید هر 1 الی 2 سال تعویض شود. دلیل این امر رشد گیاه (علی الخصوص ریشه گیاه) می‌باشد و گیاه باید برای رشد حجم بگیرد که اگر فضای گلدان محدود باشد؛ رشد گیاه شما نیز کم و پس از مدتی متوقف می‌شود. همچنین هنگام تعویض گلدان گل بابا آدم توصیه می‌شود خاک آن را نیز تعویض نمایید",
    img:babaAdam,
    category: "گیاه سایز بزرگ"
  },
  {
    id:2,
    title: "فیکوس الاستیکا",
    desc:"گیاه فیکوس الاستیکا (معروف به گیاه لاستیکی، درخت لاستیکی، گیاه درختی لاستیک) به دلیل برگهای واکسی و ظاهر بزرگ آن یکی از گیاهان آپارتمانی محبوب است. این گیاه بخاطر داشتن برگهای بزرگ و زیبا دکوراسیون هر خانه ای را تکمیل میکند. این گیاهان میتوانند تا بیش از 30 متر در زیست بوم خود در جنوب شرقی آسیا رشد کنند. به عنوان یک گیاه آپارتمانی ، فیکوس الاستیکا بین1 تا 3 متر در هر مکانی ارتفاع میگیرد.",
    protaction:"کلید نگهداری از فیکوس الاستیکا تعادل است که به معنای مقدار صحیح آب و نور است. اگر بتوانید مقدار درستی از هر دو را برایش فراهم کنید، شما یک درخت فیکوس شاد، قوی و بلند خواهید داشت.",
    protactionT:"نور گیاه فیکوس نور غیر مستقیمی که گرم نباشد را ترجیح میدهد. نور مستقیم خورشید میتواند منجر به سوخته شدن برگهایش شود. میتوانید این گیاه را نزدیک به پنجره با پرده نگهدارید تا مقدار درستی از نور به گیاه برسد. گونه های برگ رنگی این گیاه نیاز به نور بیشتر دارند تا رنگ برگهایشان بوجود آید، پس اطمینان حاصل کنید گیاه تان نور کافی دریافت میکند.",
    protactionS:"آب این گیاهان در طول فصل رشد یعنی در تابستان نیاز به آب بیشتری دارند. باید خاک را مرطوب نگهدارید، اما نه غرقابی. همچنین باید برگ های گیاه را با دستمال پارچه ای نمناک تمیز کنید تا آنها را مرطوب نگهدارید و به گیاه کمک کنید نور بیشتری را جذب کند. اگر نخواهید هر برگ را دستمال بکشید مه پاشی راه دیگر است. فیکوس را در خاک با زهکشی خوب بکارید تا همیشه با پوسیدگی ریشه بجنگد. در فصل خواب یعنی در زمستان نیاز است خاک را خشک نگهدارید اما نه خیلی خشک.",
    img:FikusElastik,
    category: "گیاه سایز بزرگ"
  },
  {
    id:3,
    title: "فیکوس آلتیسیما",
    desc:"گل فیکوس آلتیسیما (Ficus altissima) عضو خانواده Moraceae به شمار می‌آید و خاستگاه آن جنوب شرقی آسیا است. این گیاه آپارتمانی را به نام‌های انجیر بلند یا درخت شواری آسیایی نیز می‌شناسند. در ارتباط با این گل خوب است بدانید که در طبیعت دوست دارد روی شاخه گیاه دیگر رشد کند. خرید فیکوس آلتیسیما برای افرادی که به گل‌های خاص و با شرایط نگهداری راحت نیاز دارند بهترین گزینه است",
    protaction:"فیکوس آلتیسیما در محیطی که دمای هوا متعادل باشد رشد فوق‌العاده‌ای دارد. اما بهترین دمای نگهداری این گل بین 15 تا 27 درجه سانتی‌گراد است. بنابراین پس از خرید فیکوس اگر شرایط دمایی آن را فراهم کنید گیاهی سرسبز و سرزنده خواهید داشت. از طرفی دیگر این گیاه به کاهش دمای ناگهانی حساس است و نباید در محیطی با دمای کمتر از 12 درجه سانتی‌گراد قرار گیرد چرا که آسیب خواهد دید. ",
    protactionT:"در صورتی‌که گیاه را در محیطی با نور غیر مستقیم قرار دهید نیاز نوری آن برطرف خواهد شد. در این حالت برگ‌های فیکوس آلتیسیما شکیل‌ترین رنگ را خواهند داشت. هرگز این گیاه را در برابر نور مستقیم خورشید قرار ندهید چرا که منجر به سوختن برگ‌ها خواهد شد. اگر گلدان گل را در محیط باز قرار داده‌اید باید بدانید که فیکوس آلتیسیما زیر تابش مستقیم نور خورشید نیز رشد می‌کند. چنانچه تصمیم دارید فیکوس آلتیسیما را در مکان دیگری قرار دهید بهتر است به محیطی سرپوشیده که ایجاد سایه کرده است منتقل کنید.",
    protactionS:"مناسب‌ترین زمان آبیاری موقعی است که حدود 5 سانتی‌متر خاک سطح گلدان خشک شده باشد. برای تشخیص زمان آبیاری می‌توانید خشکی خاک را با انگشت بسنجید. برای این کار ابتدا فیکوس آلتیسیما را آبیاری کنید، پس از چند روز انگشت خود را داخل خاک ببرید اگر تا 5 سانتی متر بالای آن خشک بود زمان آبیاری بعدی فرا رسیده است.",
    img:FikusAltesima,
    category: "گیاه سایز بزرگ"
  },
  {
    id:4,
    title: "بونسای شفلرا",
    desc:"شفلرا به تنهایی یک گیاه زیبای آپارتمانی است که اگر با سبک بونسای ( نوعی هنر ژاپنی در باغبانی که گیاهان آپارتمانی را به شکل درختچه‌های مینیاتوری می‌کارند) کاشته شود، زیباییش باوجود ظاهری مینیاتوری و درختچه‌ای چندین برابر می‌شود.در نگاه اول به بونسای شفلرا عاشق برگ‌های سبز و مینیاتوری آن می‌شوید که به حالت چترمانند روی شاخه‌های ظریف گیاه رشد کرده‌اند. مقاومت بالا، سازگاری با شرایط محیطی مختلف و شرایط نگه‌داری آسان از دیگر ویژگی‌هایی است که در بونسای شفلرا وجود دارد.",
    protaction:"این گیاه در اصل بنسای نیست و فقط به خاطر ظاهر و تنه آن که شبیه به بنسای است، در دسته بنسای ها قرار گرفته شده، از نور مستقیم بیزار است و عاشق نور غیر مستقیم می باشد. به دلیل زادگاهش که جنگل های استوایی است، هوای شرجی و رطوبت را بسیار دوست دارد و از هوای سرد بیزار است. خاکی که انتخاب می کنید باید ترکیبات آن شامل: کوکوپیت و پیت ماس باشد.",
    protactionT:"",
    protactionS:"",
    img:Shefelra,
    category: "گیاه سایز بزرگ"
  },
  {
    id:5,
    title: "بونسای میکروکارپا",
    desc:"فیکوس میکروکاپرا گونه ای از فیکوس در خانواده موراسه است که به طور گسترده ای به عنوان یک گیاه زینتی پرورش داده میشود و یکی از شایع ترین درختان خیابانی در مناطق با آب و هوای گرم است. فیکوس میکروکاپرا بونسای در اصل از کوههای چین و در زیر صخره های این منطقه رشد می کند. علی رغم اینکه بسیار کوچک در عرض و ارتفاع هستند آنها هم میوه و هم گل در فصول و سنین مختلف میدهند",
    protaction:"فیکوس رتوزا یا جینسینگ (میکروکارپا) باید در محلی با نور زیاد قرار بگیرد، هر چند در مقابل نور کم هم مقاوم است.در فصل بهار و تابستان می‌توان در خارج از منزل نیز از آن نگهداری کرد، اما در ماه های گرم سال در مکان سایه قرار دهید. بونسای فیکوس در ماه‌های سرد سال باید در فضای بسته و گرم نگهداری شود. مکان مناسب برای نگهداری از بونسای در نزدیکی پنجره جنوبی و یا غربی است. دقت داشته باشید که نور مستقیم خورشید از پشت پنجره به بونسای شما صدمه میزند.",
    protactionT:"فیکوس بومی مناطق گرمسیر است و باید در پاییز و زمستان از سرما محافظت شود.حداقل دمای مناسب برای فیکوس ۱۵ درجه است و کمتر از ۱۰ درجه نشود. دمای ایده‌آل برای نگهداری از بونسای فیکوس رتوزا همان دمای اتاق نشیمن شما است. بونسای را نباید در نزدیکی منبع گرما (مثل بخاری و سوفاژ و..) و یا در مقابل باد سرد (مثل باد کولر) قرار داد.",
    protactionS:"آبیاری بونسای فیکوس باید زمانی انجام شود که خاک کمی خشک شده باشد، آبیاری به اندازه‌ایی باشد که آب از زیرگلدان خارج شود. دقت کنید آب نباید در سطح خاک باقی بماند و باید به سرعت خارج شود.هرگز اجازه ندهید خاک کاملا خشک شود. فیکوس گاهی اوقات می‌تواند آبیاری بیش از حد و آبیاری کم را تحمل کند. اما تداوم شرایط منجر به خشک شدن آن می‌شود. آب بدون کلر با دمای اتاق مناسب آبیاری است.",
    img:Bonsay,
    category: "گیاه سایز بزرگ"
  },
  {
    id:6,
    title: "فیکوس آمستل",
    desc:"فیکوس آمرست گیاهی از خانواده انجیر هاست نگهداری آن  مثل پسر عموی ناتنی خود کمی نیاز به وقت گذاشتن دارد ولی در کل چند پله راحت تر از بنجامین است و لوسی و حساسی بنجامین را ندارد.گونه ای که ما امروز به Ficus  میشناسیم در واقع گونه ی هیبرید شده یا به زبان ساده اصلاح شده از جد بزرگ خود  Ficus Alii از مناطق جنوب شرقی آسیاست.دارای برگ های براق و باریک و زیباست و در طی رشد خود برگ های پایین ریخته شده و ساقه ی لخت شده هم زیبایی خاص خود را دارد",
    protaction:"این گیاه آپارتمانی می‌تواند در نور متوسط تا روشن به خوبی عمل کند. با این حال، بهتر است فیکوس آمستل را در مکانی با سایه جزئی و به دور از نور مستقیم خورشید نگهداری کنید. درصورتی که، فیکوس آمستل را در مکانی با نور کم نگهداری کنید، رشد آن کندتر و زمان‌بر خواهد بود. برای اطمینان از دریافت نور کافی توسط این گیاه، آن را به صورت هفتگی بچرخانید. حتی مقدار کمی از نور روشن و غیر مستقیم خورشید به حفظ شکل آمستل کمک می‌کند.",
    protactionT:"از آبیاری بیش از حد فیکوس آمستل خودداری کنید. چرا که ممکن است دچار ریزش برگ شود. مراقب باشید که این گیاه غرقاب نشود. در فاصله بین دو آبیاری صبر کنید تا چند سانتی‌متر از سطح خاک خشک شود.",
    protactionS:"گیاه آمستل نسبت به نوع خاک خیلی حساس نیست. هر مخلوط گلدانی با زهکشی و هوادهی خوب می‌تواند مفید باشد. فقط مطمئن شوید که خاک آن غرقاب نماند و گلدان دارای سوراخ‌های زهکشی باشد.",
    img:FikusAmestel,
    category: "گیاه سایز بزرگ"
  },
  {
    id:7,
    title: "پتوس برگ پهن",
    desc:"پتوس، با نام‌های دیگری همچون “Devil’s Ivy” یا “Golden Pothos”، یکی از محبوب‌ترین گیاهان داخلی در سراسر جهان است. با ویژگی‌های زیبایی‌شان، سازگاری با شرایط مختلف و نیازهای نگهداری کم، این گیاهان به یکی از انتخاب‌های اصلی برای دکوراسیون منازل و محل‌های کار تبدیل‌شده‌اند.",
    protaction:"رای داشتن پتوس برگ‌ پهن، باید نوع گیاهی را انتخاب کنید که به طور طبیعی برگ‌های بزرگ تولید می‌کند. پتوس برگ پهن ابلق، پتوس برگ پهن چوبدار، هاوایی و طلایی را انتخاب کنید، زیرا این انواع، برگ‌های بزرگ سفید مایل به کرم را تولید می‌کنند",
    protactionT:"آبیاری کم می‌تواند اندازه برگ‌ها را تحت تأثیر قرار دهد و آبیاری زیاد می‌تواند باعث مشکلات قارچی شود. پس زمانی که خاک سطحی کمی یا کاملاً خشک است، گیاه را آبیاری کنید.",
    protactionS:"در محیط طبیعی خود، این گیاهان بر روی درختان اطراف خود می‌روند و نور خورشید و جریان هوای کافی را دریافت می‌کنند که منجر به رشد برگ می‌شود. گیاه را هدایت کنید تا بر روی یک میله، درخت نزدیک یا یک لوله در حیاط بالا برود. همچنین، مطمئن شوید که گیاه بیش‌ازحد پشتیبانی خود رشد نکند، زیرا شروع به افتادن می‌کند و منجر به برگ‌های کوچک می‌شود.",
    img:Putos,
    category: "گیاه سایز بزرگ"
  },
  {
    id:8,
    title: "یوکا",
    desc:"گیاه یوکا، گیاهی مقاوم و همیشه سبز و زیبا با برگ های شمشیری شکل و تیز می باشد. یوکا جز گیاهان زینتی است که نیاز به آبیاری کمی دارد. برای کسانی که در نگهداری گیاهان مبتدی هستند، یوکا گیاه مناسبی به حساب می آید. میوه این گیاه خاصیت درمانی دارد.",
    protaction:"گهداری یوکا ساده بوده و به راحتی می‌توان آن را در داخل گلدان و (منزل) آپارتمان، محیط های کاری، راهروها یا بیرون از خانه نگهداری و پرورش داد. در این مقاله به بررسی و طرز نگهداری از یوکا بصورت کامل خواهیم می پردازیم؛ با ما همراه باشید.",
    protactionT:" همانطور که گفته شد، نگهداری گیاه یوکا بسیار آسان است به همین دلیل برای کسانی که با نگهداری گل و گیاه آشنایی کمی دارند، می توانند به راحتی گیاه یوکا را نگهداری و پرورش دهند. از گیاه زینتی یوکا می توان در آپارتمان، انواع محیط کار و بیرون از منزل نگهداری کرد. در زیر به شرایط نگهداری یوکا مانند آبیاری، نور مورد نیاز، خاک و غیره خواهیم پرداخت",
    protactionS:"گیاه یوکا بیشتر در مناطق کویری و خشک رشد می‌کند، پس آبیاری زیاد را دوست ندارد و آبیاری بیش از حد، باعث پوسیدگی ریشه و از بین رفتن گیاه می‌شود. در فصل رشد گیاه یوکا یعنی در بهار و تابستان، می توانید تا 1 روز در هفته آن را آبیاری کنید و در فصل پاییز و زمستان میزان آبیاری را کم کرده و حدود 14تا 21 روز یکبار آن را آب دهید.   بهترین زمان برای آبیاری یوکا هنگامی است که خاک گلدان تا عمق 5سانتیمتری خشک شده باشد. از گلدانی برای یوکا استفاده کنید که زهکشی مناسبی داشته باشد و آب اضافی را خارج کند. نکته دیگر هنگام آبیاری یوکا، دقت کنید آب بر روی برگهای آن ریخته نشود.",
    protactionF:"گیاه یوکا، نیاز به نوری غیرمستقیم و کامل دارد. البته در گونه یوکا درختی میزان نور مورد نیاز گیاه به طور متوسط می باشد. این گیاه حداقل 6 ساعت نور لازم دارد و اگر مقدار نور تامین شده کمتر از این مقدار باشد،  باعث کمرنگ شدن برگهای زیبای یوکا می‌شود. همچنین نور زیاد و مستقیم، باعث سوختگی یا ریزش برگ های یوکا می شود. پس فراهم کردن نور کافی و مناسب از دغدغه های گیاه یوکا است.",
    protactionFF:"گیاه یوکا به دلیل اینکه رشد کندی دارد، نیاز به کود خاصی ندارد. در فصل رشد یوکا یعنی در بهار و تابستان برای رشد سریع و پررنگ بودن برگ ها، باید ماهانه به آن کود داده شود و می توانید از کودهای کاملی مانند کود 36-12-12 یا  کود 20-20-20 استفاده کنید. جهت استفاده از این کودها، به اندازه نصف دستور نوشته شده بر روی آن رقیق کنید و سپس از آن برای کوددهی استفاده کنید و قبل از کود دادن به گیاه، ابتدا سطح خاک را آبیاری کنید. در زمستان نیز هر دو یا سه ماه یکبار می توانید به گیاه کود دهید.",
    img:Yuka,
    category: "گیاه سایز بزرگ"
  },
  {
    id:9,
    title: "لیندا",
    desc:"این گیاه به نخل دم اسبی و لیندا مشهور است. بومی جنوب غرب آمریکا و مکزیک است و به زندگی بیابانی عادت دارد. برگ های آن باریک و کشیده و ضخیم و مقاوم، مجنون و آویزان و فنری است. این برگ ها سطح مقطع کمی دارند تا مانع از هدر رفتن آب گیاه شوند. حالت زیبای آن ناشی از سرازیر شدن برگ ها به طرف پایین بخصوص در سنین بالاست. انتهای ساقه ضخیم می شود و شکل عجیبی به خود می گیرد و نام انگلیسی آن به معنی پای فیل به همین علت است. پایه بسیار بزرگ و ورم کرده گیاه، آب را در خود ذخیره می نماید.",
    protaction:"لیندا در بهترین زمان نیز به آرامی رشد می کند و ممکن است حتی در سایه نیز به رشد خود ادامه دهد. اما برای ادامه حیات و موفقیت در رشد به نور خورشید نیاز دارد. با تامین نور متوجه خواهید شد که برگ های گیاه رشد کرده و درخشان تر شده اند، پس نور بیشتر برابر با رشد بیشتر خواهد بود. برای نگهداری لیندا در آپارتمان باید مکانی را در نظر گرفت که نور مستقیم خورشید به جز ماه های تیر و مرداد روزانه چهار ساعت یا بیشتر در آنجا بتابد. در غیر اینصورت با فراهم نمودن نور مصنوعی که حداقل بیش از نور هشتصد شمع است با مدت 12 ساعت در روز استفاده کرد",
    protactionT:"در پایه نخل لیندا ساقه ای متورم و بسیار ضخیم (غده مانند) وجود دارد که ظاهری چوبی داشته و مخصوص ذخیره سازی آب می باشد و در دوران خشکسالی از گیاه حمایت می کند .حداقل چند بار در ماه ( در تابستان یکبار در هفته) گیاه باید آبیاری شود. لیندا می تواند تا یک سال آب را در پایه خود نگهداری کند بنابراین آبیاری بیش از حد منجر به نابودی گیاه خواهد شد. بهتر است رطوبت خاک پایین باشد و خاک گلدان در میان دو آبیاری کاملا خشک شود. در فصل رشد گیاه لیندا بهتر است آبیاری هر سه هفته یک بار انجام شود. اما در فصل زمستان یک بار آبیاری کافی است، یعنی به اندازه ای که از پژمرده شدن گیاه جلوگیری کند.",
    protactionS:"گیاه لیندا خاک لیمونی را دوست دارد. خاک لوم یا لیمونی که متخصصان برای رشد لیندا توصیه می کنند ترکیبی از خاک رس، شن و مواد آلی است. به طور کلی خاکی که پس از طغیان رودخانه ها در کنار آنها بجا می ماند خاک لیمونی نام دارد. این خاک معمولا در بیابان های مکزیک وجود دارد و بسیار سبک است. البته باغبان ها با روش های مختلفی، خاک ترکیبی تهیه می کنند. در صورت مشاهده پیت ماس و هوموس در خاک لیمونی، تعجب نکنید. این مخلوط دارای ارزش تغذیه ای بسیار بالایی برای رشد گیاه است.",
    protactionF:"برخلاف دیگر گیاهان خانگی لیندا شرایط سخت را می پذیرد بدان معنا که می تواند تا دمای تقریباً زیر صفر را قبول کند. دمای کم تر از 7 درجه سانتی گراد هدف گیاه می باشد اما با دمای بالا تا 21 درجه سانتیگراد نیز طاقت می آورد. حرارت بالا را دوست دارد اما با حرارت متوسط نیز سازگار است. در دامنه دمایی 12 تا 21 درجه رشد کرده و به سرما حساس است. دمای لازم برای زمستان گذرانی حداقل در حدود 10 درجه سانتیگراد است.",
    protactionFF:"اگر رطوبت لیندا خیلی کم باشد لبه ها و نوک برگ گیاه قهوه ای خواهد شد و در غیر این صورت رطوبت بیش از حد مهم نیست. در طول سال به هفته ای یکبار غبارپاشی نیاز دارد. اما در دمای زیر 10 درجه نباید غبارپاشی کرد. ",
    img:Linda,
    category: "گیاه سایز بزرگ"
  },
  {
    id:10,
    title: "سانسوریا پاکوتاه",
    desc:"سانسوریا پاکوتاه همان‌طور که از نام‌شان مشخص است، گیاهانی هستند با قد کوتاه و برگ‌هایی پهن. به‌ خاطر همین شکل و ظاهر هم هست که اغلب برای نگهداری در فضاهای کوچک و مینیمال مناسب هستند، زیرا به‌راحتی می‌توان آن‌ها را روی میز یا روی یک بلندی گذاشت و از تماشای آن لذت برد.",
    protaction:"سانسوریا پا کوتاه یکی از آن گیاهان قانع و کم‌آبی است که باید در آب دادن به آن، احتیاط کرد. این گیاه سبز دوست‌داشتنی کوچک، به‌خاطر برگ‌های پهن و گوشتی خود قادر است، آب مورد نیازش را برای مدتی درون برگ‌ها ذخیره کند. بنابراین اگر شما در آبیاری افراط کنید، نه‌تنها لطفی در حق گیاه نکرده‌اید، بلکه باعث پوسیدگی ریشه آن هم می‌شوید. شما در فصل‌های گرم سال یعنی بهار و تابستان باید هر 7 تا 10 روز یک‌بار به گیاه سانسوریا آب بدهید و در فصول سرد سال (پاییز و زمستان) هر دو هفته یک‌بار این کار را انجام دهید. ",
    protactionT:"نور آفتاب یکی از آن لوازم و شرایطی است که هر گیاهی به میزان مشخصی به آن احتیاج دارد. با این‌که گل سانسوریا پا کوتاه نسبتا به نور کمی احتیاج دارد و در شرایط نور غیرمستقیم و سایه نیز می‌تواند به رشد خود ادامه دهد، اما نباید از این نکته غافل شد که سانسوریا پاکوتاه ابلق نسبت به نوع سبز و معمولی آن به نور و آفتاب بیشتری احتیاج دارد. زیرا برای ماندگاری و دوامِ رنگ طلایی دور برگ‌ها باید در معرض آفتاب باشد. بنابراین پشت یک پنجره نورگیر بهترین مکان برای نگهداری از سانسوریا پاکوتاه از نوع ابلق آن است",
    protactionS:"خاک وطنِ یک گیاه است. به همین خاطر باید برای گیاه مرغوب و باکیفیت باشد تا خودِ گیاه نیز به‌خوبی در آن فرصت رشد و چشم‌نوازی داشته باشد. خاک مناسب سانسوریا پاکوتاه خاکی است که ترکیبی از شن و ماسه و مواد مغذی داشته باشد. دلیل مناسب بودن چنین خاکی برای سانسوریا این است که هرچقدر خاک بافت متراکم و سختی نداشته باشد، انتقال آب در آن به‌راحتی انجام می‌شود. به همین دلیل برای جلوگیری از پوسیده شدن ریشه و روان شدن آب در بین ذرات خاک، باید از چنین خاکی استفاده کرد که زهکشی‌شده شده است.",
    protactionF:"همان‌طور که سرما یا گرمای زیاد می‌تواند موجب کلافگی انسان شود، هر گیاهی برای زنده ماندن به دمای مناسبی هم نیاز دارد که مختص خودش است. دمای ایده‌آل برای نگهداری سانسوریا پاکوتاه سبز و ابلق، دمای اتاق است؛ یعنی دمایی بین 16 تا 25 درجه سانتی‌گراد. کم یا زیاد شدن این دما می‌تواند موجب اختلال در رشد گیاه شود. لازم به یادآوری است که دمای محیط نگهداری سانسوریا پا کوتاه نباید کمتر از 10 درجه باشد.",
    img:SansoriaPakuta,
    category: "گیاه سایز بزرگ"
  },
  {
    id:11,
    title: "سانسویا",
    desc:"گیاه سانسوریا از جمله گیاهان آپارتمانی بسیار پر طرفدار با شرایط نگهداری ساده نسبت به نگهداری سایر گیاهان آپارتمانی است که می تواند خود را با عموم محیط های کم نور و کم آب وفق دهد. این گیاه آپارتمانی خواص بسیاری دارد که از مهم ترین آنها می توان به خاصیت تصفیه کنندگی آن اشاره کرد. ترکیب متعادل رنگ های زرد و سبز موجود در این گیاه، جذابیت خاصی به آن بخشیده که در برخی گونه های آن بیشتر به رنگ سبز و یا زرد متمایل می شود.",
    protaction:"برخلاف اکثر گیاهان آپارتمانی، گیاه سانسوریا تقریبا با هر شرایط نوری (نور زیاد یا نور کم) مقابله کرده و دوام می آورد. اما اگر میخواهید نور مناسب گیاه سانسوریا را برایش فراهم کنید، بهتر است در فضایی به دور از نور مستقیم خورشید قرار بگیرد. اگر در فضای باز است آن را در زیر سایه بان قرار دهید و اگر در خانه نگهداری می شود قرار گرفتن در جایی که نور از پشت پرده توری به آن بتابد، شرایطی عالی است. برگ های آن نسبت به نور حساس هستند و خیلی زود در اثر تابش مستقیم آفتاب می سوزند. به خاطر داشته باشید که گونه های ابلق به نور بیشتری نیاز دارند. در صورت کمبود نور برای این گونه ها، رده های زرد رنگ گیاه، کمرنگ خواهند شد.",
    protactionT:"آبیاری درست از نکات اساسی در روش نگهداری گل سانسوریا است. این گیاه به دلیل داشتن ساقه های گوشتی و آبدار نیاز کمی به آب دارد. بهترین زمان برای آبیاری سانسوریا شمشیری بعد از خشک شدن خاک گلدان است. از آبدهی بیش از حد خودداری کنید. آبیاری زیاد باعث بوجود آمدن زخم های قهوهای رنگی بر روی برگ ها می شود و امکان پوسیدن برگ ها از قسمت پایین و در نزدیکی سطح خاک را به وجود می آورد. آبیاری خیلی کم هم باعث چروکیدگی برگ ها می شود. از آبپاشی و غبار پاشی روی ساقه ها به دلیل خطر رشد قارچ ها خودداری کنید.",
    protactionS:"خاکی با زهکشی خوب یکی از مهم ترین عوامل برای نگهداری گل سانسوریا است. زیرا رطوبت زیاد باعث پوسیدگی ریشه و ساقه گیاه می شود. پس انتخاب مخلوط گلدانی که بتواند آب را به خوبی از خود عبور دهد یک نکته مهم است.",
    protactionF:"این گیاه تا دمای ۱۲ الی ۱۵ درجه رو به خوبی تحمل می کند و دمای ایده آل برای این گیاه ۱۸ الی ۲۵ درجه سانتی گراد می باشد ولی با افزایش دما دقت کنید که رطوبت و میزان آبیاری باید افزایش پیدا کند.",
    img:Sonsuria,
    category: "گیاه سایز بزرگ"
  },
  {
    id:12,
    title: "افوربیا قرمز",
    desc:"تریگونا قرمز از جمله ساکولنت‌ها محسوب می‌شود. شاید این گیاه از نظر ظاهری تفاوت زیادی با کاکتوس‌ها نداشته باشد اما در دسته کاکتوس‌ها و خانواده آنها قرار نمی گیرد و در خانواده ساکولنت‌ها به شمار می‌آید. هرچند از نظر ظاهری افوربیا تریگونا قرمز تفاوتی با کاکتوس ها ندارد و در شیوه نگهداری نیز کاملاً یکسان هستند. ساکولنت افوربیا تریگونا قرمز با نام علمی Euphorbia trigona rubra نیز شناخته شده است. شاید شما این گیاه را با نام‌های دیگری مانند درخت شیر آفریقایی شنیده باشید.",
    protaction:"این گیاه در مقابل خشکی خاک مقاومت بسیار بالایی از خود نشان می‌دهد. با توجه به اینکه بافت و ساقه گیاه افوربیا تریگونا قرمز حالت گوشتی دارد به آبیاری کمتری نیاز دارد. اگر در فصل زمستان هستید باید میزان آبیاری گیاه قرمز کمتر باشد. میزان آبیاری نیز باید به صورت متعادل انجام شود. آبیاری بیش از حد و نامنظم باعث می‌شود که ریشه‌ها از بین برود و گیاه دچار پوسیدگی شود شما ابتدا باید اجازه دهید خاک گلدان به خوبی خشک شود و سپس اقدام به آبیاری کنید.",
    protactionT:"تریگونا قرمز به محیطی با نور کامل و زیاد نیاز دارد. اگر شما این گیاه را در بیرون از منزل نگهداری می‌کنید توصیه می‌کنیم که در روزهای گرم تابستان آن را در مقابل اشعه مستقیم نور خورشید قرار ندهید. افوربیا تریگونا‌ قرمز اگر برای مدت زمان طولانی زیر تابش مستقیم نور آفتاب قرار بگیرد دچار سوختگی می‌شود به همین دلیل شما بر روی تنه این گیاه لکه‌های قهوه‌ای مشاهده می‌کنید.",
    protactionS:"خاک افوربیا تریگونا قرمز باید سبک با زهکشی بالا انجام شده باشد. به گونه‌ای که هنگام آبیاری آب اضافی از آن خارج شود. بهترین ترکیب خاک برای این گیاه خاک برگ ماسه و پیت ماس است. شما می‌توانید مناسب پیت ماس و برگ پوسیده را نیز با یکدیگر ترکیب کنید.",
    protactionF:"این گیاه در مناطق گرمسیری و در دماهای بالا زندگی می‌کند. شما می‌توانید از این گیاه در خانه نگهداری کنید اما دمای آن نباید از زیر ۱۳ تا ۱۴ درجه سانتیگراد کمتر باشد بهترین دما بین ۲۵ تا ۳۰ درجه سانتیگراد است.",
    img:EforbiaGhermez,
    category: "گیاه سایز بزرگ"
  },   
  {
    id:13,
    title: "افوربیا",
    desc:"گیاه افوربیا تریگونا که به درخت شیر آفریقایی مشهور است بومی مناطق مرکزی قاره‌ی آفریقا است. از آن‌جا که این گیاه رشد سریعی دارد معمولاً به عنوان پرچین پرورش داده می‌شود. اگرچه افوربیا تریگونا شباهت زیادی به کاکتوس دارد اما در واقع گونه‌ای متعلق به سرده‌ی افوربیا است و افوربیا نام سرده‌ای بسیار بزرگ از گیاهان است که بیش از ۲۰۰۰ گونه‌ی مختلف را شامل می‌شود. نزدیک به ۱۲۰۰ گونه از افوربیاها و از جمله افوربیا تریگونا ساکیولنت‌ هستند.",
    protaction:"اگرچه در بعضی مناطق افوربیا تریگونا گیاهی محبوب برای تزیین باغچه‌ها است، اما در بیشتر مناطق به عنوان یک گیاه آپارتمانی طرفداران زیادی دارد. افرادی که به پرورش گیاهان علاقه دارند، معمولاً به دنبال این هستند که افوربیا تریگونا را به گونه‌ای پرورش دهند که گیاه به بیشترین ارتفاع خود برسد. البته این کار به مراقبت‌های زیادی نیاز دارد که هرس و بستن گیاه به یک پایه بخشی از این مراقبت‌ها است.",
    protactionT:"افوربیا‌‌‌ تریگونا نور زیاد اما غیرمستقیم را می‌پسندد. اگر می‌خواهید در فضای بسته از این گیاه نگهداری کنید، کنار پنجره‌های جنوبی به خوبی رشد می‌کند و در فضای باز، باید گیاه را در مکانی قرار دهید که کمی آفتاب‌گیر باشد. البته در بهار، پاییز و زمستان می‌توانید گیاه را در مکانی که کاملاً آفتاب‌گیر است قرار دهید اما در تابستان باید گیاه را به مکانی انتقال دهید که دائماً در معرض نور خورشید قرار نگیرد. اگر افوربیا تریگونا در مکانی قرار داشته باشد که بیش از اندازه نور و حرارت خورشید را دریافت کند، شاید لازم باشد آبیاری گیاه را افزایش دهید تا گیاه آسیب نبیند.",
    protactionS:"اگرچه افوربیا تریگونا درباره‌ی خاک سخت‌گیر نیست اما برای این گیاه زهکشی مناسب ضرورت دارد. البته اگر می‌خواهید از خاک‌ رسی و متراکم برای پرورش گیاه استفاده کنید باید آن‌ را از نظر زهکشی و pH خاک اصلاح کنید چرا که این گونه خاک‌ می‌تواند مانع رشد گیاه بشود.",
    protactionF:"افوربیا تریگونا به آبیاری زیادی نیاز ندارد. اگر در منطقه‌ای زندگی می‌کنید که با خشک‌سالی مواجه نیستید، بارندگی طبیعی منطقه‌ی شما برای آبیاری این گیاه کافی است. اما در شرایط خشک‌سالی باید گیاه را آبیاری کنید.اگر از افوربیا تریگونا در فضای بسته نگهداری می‌کنید، یک بار آبیاری در هفته می‌تواند کافی باشد. البته برای اینکه شرایطی مشابه با زیستگاه طبیعی گیاه فراهم کنید، باید زمانی برای آبیاری مجدد اقدام کنید که خاک گلدان خشک شده باشد.",
    img:Eforbia,
    category: "گیاه سایز بزرگ"
  },

//////////   
  {
    id:14,
    title: "وریزیا قرمز",
    desc:"با انتخاب متفاوت و جذاب گیاه وریزیا بروملیاد قرمز جلوه‌ای تازه به منزل‌تان بدهید. این گیاه سازگار، بومی جنگل‌های آمریکای جنوبی و مرکزی است و امروزه در سراسر جهان به عنوان یک گیاه زیبای آپارتمانی پرورش داده‌می‌شود. وریزیا اندازه‌ی متوسط تا بزرگ دارد و جذاب‌ترین قسمت آن، گل خاص و متفاوت‌ش است که ماندگاری‌اش زیاد است. گل وریزیا ساقه‌ی بلند و رنگ‌های چشم‌نوازی مثل سبز، قرمز، نارنجی و زرد دارد.",
    protaction:"نکته‌ی مثبت وریزیا اینست که با مقدار کم و زیاد نور خورشید کاملا سازگار است و می‌تواند هم در سایه و هم در محیط‌های پرنور(به صورت غیرمستقیم) زندگی کند اما برگ‌های وریزیا در نور کامل، خوشرنگ و خوش‌فرم می‌شوند. یادتان باشد هرچه وریزیا نور بیش‌تری دریافت‌کند، نیاز به آب بیش‌تری دارد تا برگ‌هایش خشک‌نشود. بهترین قسمت خانه برای وریزیا، پشت پنجره‌های شرقی و جنوبی است که با پرده‌ی نازکی پوشیده شده است",
    protactionT:"وریزیا دمای متوسط یعنی حدود 25 درجه را دوست ‌دارد و در این دما گل می‌دهد اما می‌تواند دمای ۱ تا ۳۲ درجه‌ی سانتی‌گراد را هم برای مدت کوتاهی تحمل‌کند. اگر در جایی زندگی می‌کنید که در زمستان دمای هوا به زیر صفر می‌رسد، هرگز گلدان وریزیا را درهوای سرد و در فضای باز نگذار",
    protactionS:"آبیاری گیاه وریزیا بروملیاد قرمز نسبت به سایر گیاهان متفاوت است و به جای خاک گلدان، لازم است که حفره‌ی میانی برگ‌های آن همیشه آب داشته باشد. هم‌چنین به خاک همیشه مرطوب حساس است و احتمال پوسیدگی ریشه در اثر آبیاری زیاد وجود دارد پس لازم است که فقط از طریق حفره‌ی وسط آبیاری کنید و خاک گلدان کمتر مرطوب شود. کافی است هفته‌ای یک بار در وسط حفره‌ی میانی برگ‌ها (روزت کاپ) آب تازه و بدون کلر بریزید و به اندازه‌ای آب بریزید که حفره پر شود و آب اضافی روی خاک گلدان بریزد.",
    protactionF:"وریزیا در جنگل‌ها به صورت هوازی رشد می‌کند و مستقیما با خاک در تماس نیست اما شما می‌توانید آن‌را در گلدان و خاک سبک با زهکش خوب بکارید. خاک دارای پیت ماس و کمپوست برای وریزیا بسیار مناسبند.",
    img:Viritz,
    category: "گیاه گلدار"
  },
  {
    id:15,
    title: "شیپوری",
    desc:"گل شیپوری دارای ریزوم (ساقه زیر زمینی غده ای) ضخیم و دم‌گل قوی و بلند است. معمولا از هر یک از ریزوم‌های این گیاه، ۱، ۲ یا ۳ گل به‌ وجود می‌آید، ولی در پایه‌های قوی، تعداد گلهای هر ریزوم به ۸ تا ۱۰ عدد خواهد رسید.گل‌ها در این گیاه، شبیه به شیپور هستند.",
    protaction:"گل شیپوری در رابطه با دما نسبتا حساس است. برای رشد ایده آل، پیاز در طی روز نباید در دمای بالاتر از 18 درجه و پایین تر از 13 درجه سانتی گراد قرار بگیرد. قسمت های بالای سطح خاک گیاه می توانند دمای بالاتر از 24 درجه سانتی گراد را تحمل کنند. اگر منزل شما گرم است، روی سطح خاک از مالچ (پوششی نرم وسبک بر روی خاک متشکل از خاک اره، کاه، خاک برگ و...) استفاده کنید تا پیاز در داخل گلدان خنک بماند.  نور: گل شیپوری نیازمند نور کافی خورشید است. با قرار دادن گیاه در کنار پنجره های شرقی و یا غربی و با فراهم کردن نور کافی، شرایط را برای رشد ایده آل این گیاه فراهم نمایید.",
    protactionT:"گل شیپوری یک گیاه آب دوست است. این گیاه در کناره های استخر و یا در گل و لای بهترین رشد خود را دارا است. با توجه به این موضوع باید در داخل خانه برای این گل شرایطی مرطوب را فراهم نمایید. خاک را همیشه مرطوب نگه داشته اما هیچ گاه اجازه ندهید تا آب در گلدان تجمع یابد. آب زیادی در گلدان باعث پوسیدگی پیاز می شود. ",
    protactionS:"گل شیپوری به صورت معمول نیاز به کوددهی ندارد. اگر تمایل به رشد بهتر برای این گیاه دارید از کودهای فاقد آمونیوم استفاده کنید. کود را در آب با توجه به نسبت توصیه شده حل کنید و هر دو هفته یک بار و تنها برای یک فصل رشد استفاده نمایید. کوددهی برای مدت طولانی موجب آسیب گیاه می گردد.",
    protactionF:"تا زمانیکه خاک گلدان مرطوب نگه داشته شود رطوبت معمول اتاق به خصوص در زمستان برای گیاه کافی است.",
    img:Sheypori,
    category: "گیاه گلدار"
  },
  {
    id:16,
    title: "سرخس لانه پرنده",
    desc:"گیاه سرخس لانه پرنده  یا سرخس لانه کبوتری یکی از چندین سرخس محبوب و زیبا است که به عنوان گیاه آپارتمانی هم پرورش می یابد. نام علمی  سرخس لانه پرنده  Asplenium nid   است. این گیاهان گرمسیری، بومی هاوایی و جزایر اقیانوس آرام هستند. گیاهان سرخس لانه پرنده  با رشد بسیار آهسته برای پرورش دهندگان گیاهچه های خانگی بسیار عالی هستند. مراقبت از گیاهان سرخس لانه پرنده، نیاز به توجه، آبیاری و تکثیر دارد؛ اما به  اندازه بسیاری از گیاهان دیگر به کود و نور احتیاج ندارد. گیاهان لانه پرنده  اگرچه متفاوت و شگفت انگیز نیستند؛اما زیبایی را  به خانه ها ، باغ های سنگی و پاسیوها می بخشند.",
    protaction:"سرخس‌های لانه پرنده ای زیبا نمونه‌های بسیار بزرگی از گیاهان گلخانه ای را به خود اختصاص داده‌اند. آن‌ها با گروهی از ارکیده ها و دیگر گیاهان جنگل‌های بارانی قابل رده بندی هستند. کلید داشتن یک گیاه سرخس لانه کبوتری سالم، فراهم کردن گرما و رطوبت کافی است. با توجه به فراهم کردن این دو شرایط، سرخس‌ها می‌توانند در برابر سطوح نور بالاتر مقاومت کنند. یکی از بهترین مکان‌ها برای قرار دادن این گیاه کنار پنجره حمام یا قسمت پنجره شمالی است، جایی که رطوبت و گرما را همراه با نور کافی به دست می‌آورد.",
    protactionT:"این گیاهان به نور فیلتر شده برای رشد نیاز دارند. آن ها را در معرض آفتاب مستقیم به غیر از خورشید صبح زود هنگام قرار ندهید. قرار دادن سرخس در یک پنجره شرقی یا شمالی ایده‌آل است.",
    protactionS:"سرخس لانه کبوتری را در کمپوست ارگانیک غنی یا ترکیبی از خاک گلدان و کمپوست بکارید. مخلوطی از پیت ماس و خاک گلدان نیز می تواند خوب باشد. در غیر این صورت، مخلوط پیت ماس با مواد آلی را امتحان کنید.",
    protactionF:"این گیاهان در طول فصل رشد (آوریل تا سپتامبر)، با کود مایع ضعیف بارور می‌شوند. در فصل زمستان، زمانی که بیشتر گیاهان در مرحله استراحت خود هستند، سرخس کود شیمیایی را در بخش مرکزی یا لانه قرار می‌دهند. کود بسیار زیاد باعث ترک برگ‌ها ویا  زردشدن آن ها خواهد شد.",
    img:Sarakhs,
    category: "گیاه گلدار"
  },
  {
    id:17,
    title: "بنت قنسول",
    desc:"گل بنت قنسول که در ایران به گل کریسمس نیز معروف است، بومی کشور مکزیک است. برگ‌های قرمز بنت قنسول واقعا زیبا و خیره کننده است و این گل از جمله گیاهان ارزان قیمت و پرطرفدار محسوب می‌شود. این درختچه زیبا با قرار گرفتن در شرایط ایده‌آل، تا ارتفاع ۳ الی ۴٫۵ متر رشد می‌کند.",
    protaction:"نور:گیاهان دارای برگ‌های رنگی نیاز به نور خیلی زیاد دارند. بنت قنسول به فضایی روشن نیاز دارد اما به دور از نور مستقیم. پس بهتره است که این گیاه را نزدیک پنجره قرار دهید. قرار گرفتن در زیر نور غیر مستقیم آفتاب به مدت ۶ ساعت در روز نیاز نوری این گیاه را برطرف می‌کند. اگر در روزهای سرد زمستان آن را کنار پنجره برای نورگیری قرار می‌دهید از برخورد آن به شیشه سرد پنجره جلوگیری کنید.",
    protactionT:"آبیاری:بنت قنسول گیاهی رطوبت دوست است. چون شرایط نگهداری از گل‌ها در مکان‌های مختلف، متفاوت است پس نمی‌شود زمان دقیقی بین دو آبیاری ذکر کرد. به طور کل ۲ تا ۳ بار در هفته بسته به دمای اتاق و یا هر وقت سطح خاک خشک شد اقدام به آبیاری کنید. به اندازه آبیاری کنید، زیرا آبیاری بیش از اندازه به گیاه آسیب می‌زند.",
    protactionS:"دما:دمای بالا مانع از گلدهی بنت قنسول می‌شود. بهترین دما برای این گل بین 15 تا 24 درجه ی سانتیگراد است و دماهای پایین تر باعث کندی رشد و دماهای بالاتر هم مانع گل‌دهی می‌شود یا گلدهی را به تاخیر می‌اندازد",
    protactionF:"خاک: از خاکی با زهکش خوب استفاده کنید. نوع خاک باید به صورتی باشد که بعد از آبیاری گل آلود و غرقاب نماند. مثلاً مخلوطی از پیت موس+ پرلایت و یا خاک برگ +کوکوپیت برای این گیاه مناسب است. این گیاه زمانی که گل دارد نیازی به کوددهی ندارد.",
    img:Bent,
    category: "گیاه گلدار"
  },
  {
    id:18,
    title: "ایکمیا فاسکیاتا",
    desc:"گیاه آپارتمانی ایکمیا فاسکیاتا، یک گیاه بومی از مکزیک و جنوب آمریکا است. گیاه ایکمیا از خانواده آناناسیان و گیاهی گل‌دار است. گلدان نقره‌ای گیاهی، همیشه سبز و ریزوم‌دار است. برگ‌های آکمیا کمی خشن و نواری شکل است، این برگ ها سبز رنگ با رگه‌های نقره‌ای و لبه کمی خار نیزدارد.",
    protaction:"در این بخش از خاک مناسب، نحوه آبیاری درست آکمیا صحبت می‌کنیم. به سوالاتی مثل دمای مناسب گل آکمئا ( Urn plant) چیست؟ نور مستقیم برای گیاه مناسب است یا غیر مستقیم؟ پاسخ می‌دهیم. و کوددهی مناسب گیاه ایکمیا فاسکیاتا را بیان می‌کنیم. نحوه تکثیر آکمیا را برای حفظ نسل زیبایی در آپارتمان شما می‌نویسیم.",
    protactionT:"آب مناسب گیاه آپارتمانی ایکمیا فاسکیاتا، آب تصفیه شده و بدون نمک است. البته برای آبیاری با آب تصفیه شده بهتراست، آب را در بطری ریخته و چند ساعت بعد آبیاری را انجام دهید. این کار باعث کمترشدن کلر آب خواهد شد. زمانی که خاک گلدان خشک است اقدام به آبیاری کنید.",
    protactionS:"خاک متناسب کیاه کوزه نقره‌ای خاکی سبک، بدون گچ و آهک است. این خاک باید برای رشد بهتر گیاه آکمیا زهکشی بالایی داشته باشد. خاک ایکمیا بهتراست، مخلوطی از خاک برگ و پیت باشد.",
    protactionF:"گل کوزه نقره‌ای، عاشق نور زیاد است. اما نور باید غیرمستقیم باشد. گل خود را از تابش مستقیم خورشید حتما دور کنید. گل Aechmea Fasciata خود را در ۱ یا ۲ متری پنچره‌ای که پرده دارد قرار دهید. این کار باعث عدم کج شدن گل به سمت نور و آسیب دیدن گل آکمیا خواهدشد.",
    img:Anturiom,
    category: "گیاه گلدار"
  },
  {
    id:19,
    title: "آنتوریوم قرمز",
    desc:"نام علمی این گل زیبا ANTHURIUM SCHERZERIANUM است. گل آنتوریوم در دسته گل های شیپوری قرار می گیرد. مناطق مرطوب گرمسیری مرکز و جنوب آمریکا به عنوان خاستگاه این گیاه شناخته شده است. گل های آنتوریوم در رنگ های مختلفی دیده می شوند که جلوه ای خاص به این گیاه می بخشند. آنتوریوم گیاهی با برگ های پهن و قلبی شکل است. این گیاه جایگاه خاصی در میان بسیاری از گل دوستان به خود اختصاص داده است",
    protaction:"گل آنتوریوم قرمز نیز مانند هرگیاهی شرایط نگهداری مخصوص به خود را دارد. برای این که محیط لازم جهت رشد این گیاه را فراهم کنید، باید از نیازهای آن آگاه باشید؛ در غیر این صورت گیاه شما آسیب خواهد دید. در ادامه برخی از فاکتورهای مهم در رشد این گیاه را بررسی کرده ایم.",
    protactionT:"آبیاری گل آنتوریوم قرمز باید به گونه ای باشد که سطح خاک بین دو وعده آبیاری خشک شود. توجه داشته باشید که میزان آبدهی در زمستان به هفته ای یک بار کاهش پیدا می کند.",
    protactionS:"بهترین نور برای آنتوریوم نور غیرمستقیم کنار پنجره است. با قرار دادن این گل در مقابل تابش مستقیم آفتاب شاهد از بین رفتن گیاه خود خواهید بود.",
    protactionF:"همه گیاهان با کمبود عناصر غذایی در خاک مواجه می شوند. اگر به رشد آنتوریوم اهمیت می دهید، هفته ای دو بار گیاه خود را کوددهی کنید. بهترین کود برای این گیاه کودهای بنفشه آفریقایی است.",
    protactionFF:"دمای مطلوب برای آنتوریوم دمای 21 درجه سانتی گراد است. این دما در زمستان به 16 درجه سانتی گراد کاهش پیدا می کند. اگر هوای محیط گرم و خشک باشد، رشد گیاه متوقف خواهد شد.",
    img:Ikima,
    category: "گیاه گلدار"
  },
  {
    id:20,
    title: "ارکید",
    desc:"گل ارکیده فالانوپسیس یکی از گونه‌های زیبای گیاهان ارکیده است. این گل با طراوت و زیبایی خاص خود، معمولاً در محیط‌های گرم و مرطوب مناطق استوایی یافت می‌شود. شکل منحصر به فرد گل‌های ارکیده فالانوپسیس، با رنگ‌های متنوع از سفید و صورتی تا بنفش، نظر هر کسی را به خود جلب می‌کند. در این مطلب از سایت گل گیفت با روش نگهداری گل ارکیده فالانوپسیس آشنا خواهید شد",
    protaction:"رکیده فالانوپسیس به عنوان یک گل تزیینی نیز در مراسم‌ها و رویدادهای مختلف مورد استفاده قرار می‌گیرد. این گل‌ها نیازمند شرایط خاصی از نور، رطوبت و دما برای رشد و گلدهی هستند که این ویژگی‌ها آن را به یک چالش برای باغبانان و علاقه‌مندان به گیاه‌آرایی تبدیل می‌کند. به طور کلی، ارکیده فالانوپسیس به عنوان یک اثر هنری زیبا در دنیای گیاهان، همچنان جذب توجه و شگفتی‌بازی مردم را به خود جلب می‌کند.",
    protactionT:"خاک مناسب برای نگهداری گل ارکیده فالانوپسیس یک عامل اساسی برای رشد و گلدهی این گیاهان زیباست. به‌طور کلی، خاکی که برای ارکیده‌ها استفاده می‌شود، باید خرده‌جلبکی و خیس باشد. مخلوطی از خاک‌های جلبکی، کوکوپیت (پوسته‌ی نارگیل خردشده) و کربن‌ریچ (مواد آلی) می‌تواند محیط مناسبی برای رشد این گیاهان ایجاد کند.",
    protactionS:"آبیاری مناسب برای گل ارکیده فالانوپسیس یکی از عوامل اساسی برای رشد و گلدهی بهتر این گیاه زیبا است. بهترین روش برای آبیاری ارکیده‌ها، استفاده از روش خراشی یا غرقابی است. در این روش، گلدان حوالی ارکیده به طور کامل در آب فرو می‌شود تا خاک و ریشه‌ها به طور یکنواخت آبگیری کنند. سپس گلدان از آب برداشته می‌شود و اجازه داده می‌شود تا زائده‌ی آب از زیر گلدان خودکار جاری شود.",
    protactionF:"یکی از مهم ترین روش نگهداری گل ارکیده فالانوپسیس بدون شک رطوبت مورد نیاز برای گیاه است. رطوبت محیطی مهمترین عامل برای رشد و بازدهی مناسب گل ارکیده فالانوپسیس است. این گیاه‌ها از محیط‌های مرطوب و استوایی بهره می‌برند، بنابراین حفظ رطوبت مناسب برای آنها بسیار حیاتی است. رطوبت هوا باید در محدوده‌ای بین ۵۰ تا ۸۰ درصد باشد.",
    protactionFF:"نور مناسب یکی از عوامل اساسی برای رشد و گلدهی بهتر گل ارکیده فالانوپسیس است. این گیاه‌ها به نور فراوان نیاز دارند، اما نور مستقیم آفتاب را تا حدی باید کنترل کنید. در طول فصول گرمتر، بهتر است از نور غیر مستقیم و نورهای صبحگاهی استفاده کنید.",
    img:Orkid,
    category: "گیاه گلدار"
  },
//////
  {
    id:21,
    title: "تراریوم جئومتریک",
    desc:"",
    protaction:"",
    img:Geo,
    category: "تراریوم"
  },
  {
    id:22,
    title: "تراریوم پوپک",
    desc:" این تراریوم برای گیاهانی مناسب است که آب و هوای خشک‌تر را بیش‌تر می پسندند و به محیط خیلی مرطوب نیازی ندارند. این نوع از تراریوم‌ها برعکس نوع بسته‌ی آن، چرخه‌ی آب ندارند چون  بخار آب با هوای اطرافش در تماس است و بیرون می‌رود. پس لازم است که نوع باز را بیش‌تر آبیاری کنید. این مقدار تقریبا تقریبا هفته ای یک‌بار است و هر وقت که سطح خاک خشک شد و قبل از پژمرده‌شدن برگ‌ها، به آن آب بدهید.",
    protaction:"بهترین قسمت تراریوم باز، مراقبت و نگه‌داری از آن برای سال‌های طولانی است. اگر تراریوم‌تان به درستی کار کند، به مراقبت زیادی نیاز نخواهد داشت. با این حال نگذارید که زحماتتان با بی توجهی و عدم مراقبت از گیاهان  داخل تراریوم به هدر برود.  برای نگهداری این گیاهان می‌توانید در مقابل نور زیاد و متناسب با نوع گیاهانش، حتی در مقابل نور مستقیم خورشید هم بگذارید. هم‌چنین هر دو هفته یک بار یا هر زمان که خاک خشک شد به آن آب دهید.    خاک مناسب در تراریوم نیمه باز به ترتیب از پایین به بالا، شامل چهار لایه‌ی سنگ، زغال، خاک گلدان و شن است.",
    protactionT:"برای نگهداری این گیاهان می‌توانید در مقابل نور زیاد و متناسب با نوع گیاهانش، حتی در مقابل نور مستقیم خورشید هم بگذارید. هم‌چنین هر دو هفته یک بار یا هر زمان که خاک خشک شد به آن آب دهید.",
    img:Pupak,
    category: "تراریوم"
  },
  {
    id:23,
    title: "تراریوم بونسای جنسینگ",
    desc:"بنسای جنسینگ یکی از زیباترین، پرطرفدارترین و پرفروش ترین بنسای های فروشگاه پلنت لند تهران می باشد. بن سای از ترکیب دو کلمه بن و سای تشکیل شده است و به معنی نگهداری درخت در گلدان می باشد. برای نگهداری درست این درخت کوچک، تزیینی و زیبا لازم به انجام کار دشواری نیست، در ادامه نکات مهمی که باعث رشد و سالم ماندن بنسای جنسینگ می شود را به شما توضیح می دهیم.",
    protaction:"سای جنسینگ یک گیاه بسیار مقاوم می باشد، به آبیاری زیادی نیاز ندارد و به نور زیادی احتیاج دارد (البته نور غیرمستقیم). اگر نور کافی به آن نرسد و کمبود نور داشته باشد، برگ هایش شروع به ریختن می کنند. برای نگهداری این بنسای گرمسیری باید آن را بین دمای 20 تا 30 درجه سانتی گراد نگهداری کنید. خاک مناسب برای این بنسای باید زهکشی درستی داشته باشد و از هیچ مواد سنگینی درون آن استفاده نشود.",
    protactionT:"موقع آبیاری به گلدان آب نباید درون گلدان باقی بماند و اضافه آن از زیر گلدان خارج شود. اگر آب داخل گلدان بماند و از خاک عبور نکند، باعث می شود ریشه بنسای پوسیده شود. بهترین خاک برای نگهداری طولانی مدت و رشد بنسای باید شامل: سنگ ریزه، ماسه شسته شده، لیکا، پرلیت، پوکه معدنی، آکاداما، خاک رس، مقداری کوکوپیت، پوست درخت کاج، پیت ماس، خاک برگ و… باشد. برای این گلدان باید از ترکیب کوکوییت، ماسه شسته شده و کمی ورمی استفاده کنید.",
    img:Jensing,
    category: "تراریوم"
  },
  {
    id:24,
    title: "کاکتوس صخره ای",
    desc:"کاکتوس صخره ای به دلیل راحت بودن روش تکثیر و همینطور زیبایی منحصر به فردی که دارد،از زمان های قدیم در خانه هایمان نگهداری شده است. این کاکتوس مقاومت بالایی هم در برابر سرما دارد. کاکتوس صخره ای به راحتی قابل پرورش و نگهداری است و اگر دنبال کاکتوسی هستید که به راحتی تکثیرش کنید و مقاومت بالایی در برابر سرما و گرما داشته باشد میتوانید از این کاکتوس تهیه کنید. از این کاکتوس میتوان در گلخانه ها ،آپارتمان ها و نمایشگاه ها به عنوان گیاهان زینتی استفاده نمود.",
    protaction:"علت خراب شدن کاکتوس صخره ای به دلیل استفاده از خاک نامناسب نیز می باشد. برای داشتن کاکتوس شاداب و سالم، بایستی از خاک خوب استفاده نمود. از این رو، استفاده از خاک های متراکم و آلوده برای این گیاه توصیه نمی شود. بهترین نوع خاک برای کاکتوس صخره ای، خاک های با زهکشی بالا است",
    protactionT:"یکی از مواردی که میتواند به کاکتوس صخره ای، آسیب وارد کرده و برای آن مضر باشد، آبیاری بیش از حد است. پس در مورد کاکتوس صخره ای، آبیاری منظم و به موقع را فراموش نکنید. بهترین زمان آبیاری این گیاه، وقتی است که خاک گلدان هیچ گونه رطوبتی نداشته و به طور کامل خشک باشد",
    protactionS:"علت خراب شدن کاکتوس صخره ای در نور نامناسب نیز خلاصه می شود. بهترین محیط برای نگهداری این گیاه، محیط پرنور است. البته آفتاب بیش از اندازه، سوختگی کاکتوس صخره ای را به دنبال دارد. پس بایستی در فصل تابستان که شدت تابش نور خورشید زیاد است، نور فیلتر شده به کار برید. نور پشت پنجره، حداقل نور برای کاکتوس صخره ای محسوب می شود.",
    protactionF:"در برخی از کاکتوس های صخره ای بیمار، تغییر رنگ در آنها مشاهده می شود. مناسب نبودن نور دریافتی یا کم بودن آن، میتواند سبب تغییر رنگ کاکتوس شود. همچنین ابتلا به بیماری قارچی نیز موجب تغییر رنگ بخش پایینی گیاه و نرم شدن بافت آن می شود. در چنین شرایطی، بررسی ریشه گیاه و استفاده از قارچ کش برای نابودی قارچ ها را پیشنهاد می کنیم.",
    img:Sakhre,
    category: "کاکتوس"
  },
  {
    id:25,
    title: "کاکتوس اچینو",
    desc:"اچینو کاکتوس دارای بزرگ‌ترین کاکتوس‌های کروی در طبیعت وحشی مکزیک و ایالات متحده است و قطر ان در خارج از گلدان به 5/1 متر می‌رسد. در نگهدای خانگی 6 – 5 سال طول می‌کشد تا سطح گلدان 18 سانتیمتری را پر کند. بعضی از آنها هنگامی که اندازه آن به قطر یک توپ فوتبال رسید گل‌های خود را ظاهر می‌سازند. خوش‌تیپ‌ترین کاکتوس‌ها هستند به گونه‌ای که در نمایشگاه‌های گل و گیاه زیبایی‌های دیگر کاکتوس‌ها را تحت‌الشعاع قرار می‌دهند.",
    protaction:"نیاز آبی این گیاه در فصل بهار و تابستان متوسط است مثلا یک بار در هفته یا زمانی که خاک خشک شد، اما آبیاری را در فصل پاییز کاهش دهید. لازم به ذکر است که در فصل زمستان فقط 2 تا 3 بار به گیاه آب بدهید.",
    protactionT:"خاک کاکتوس که زهکشی مناسب داشته باشد برای این گیاه مناسب است. اضافه کردن ماسه یا سنگ‌ریزه در انتهای خاک گلدان به زهکشی خاک کمک می‌کند",
    protactionS:"بهترین مکان برای این گیاه مکانی آفتابی و با تهویه مناسب است. اما آفتاب ظهر تابستان برای آن بسیار خطرناک است. در تابستان کاکتوس را در مکانی قرار دهید که نیمی از روز آفتاب و نیمی از روز سایه باشد. دقت کنید که به آرامی گیاه را از سایه به آفتاب منتقل کنید در غیر این صورت گیاه می‌سوزد. اگر گیاه طبیعی اچینو را در منزل خود نگهداری می‌کنید محیط باید تهویه مناسبی داشته باشد.",
    protactionF:"به طور میانگین همه کاکتوس‌ها در فصل زمستان به خواب می‌روند. مناسب‌ترین درجه حرارت برای آن‌ها 8 تا 10 درجه سانتی‌گراد است و کمترین دمای قابل تحمل برای آن‌ها 5 درجه است. ضروری است که در فصل زمستان گیاه را در داخل خاکی خشک نگهداری کنید تا از پوسیدگی ریشه آن جلوگیری کنید. اچینو را دور از محیط سرد و مرطوب نگهداری کنید که در محیط سرد خیلی زود از بین می‌رود.",
    img:Echinu,
    category: "کاکتوس"
  },
  {
    id:28,
    title: "آگاو ویکتوریا",
    desc:"آگاو ویکتوریا گونه‌ای از هزاران واریته آگاوها می‌باشد. اسم این آگاو توسط توماس مور به احترام ملکه ویکتوریا انگلیس نام گزاری شده است. جز گیاهان کم رشد می‌باشد. قطر اگاو ویکتوریا تا ۴۵ سانتی متر نیز رشد می‌کند. دارای برگ‌های محکم و بسیار زیبایی می‌باشد.",
    protaction:"این گل مرد روزهای سخته چون که واقعا به شرایط سخت مقاومه،به نور زیاد احتیاج داره آفتاب مستقیم واسش لازمه توی سایه و جای کم نور اصلا خوب جواب نمیده توی اتاق پنجره جنوبی بهترین جاست.",
    protactionT:"نیاز آبی این گیاه بالا است ولی چون به پوسیدگی ریشه حساس است نباید بیش از اندازه آب داده شود. در مناطق با زمستان معتدل می‌توان آن را در هوای آزاد نگهداری کرد.",
    protactionS:"به هوای سرد و یخ بندان حساسه به جز گونه ی امریکایی ،بقیه رو نمیشه تو حیاط تو زمستون نگه داشت باید با پلاستیک دورشو پوشانید.گرمای زیاد هم براش مهم نیست.",
    protactionF:"با این که به این چیزا اعتقادی نداره ولی ماهی یکبار دوماهی یکبار با کود کاکتوس یا آب ماهی تغذیه کنید.",
    img:Victoria,
    category: "کاکتوس"
  },
  {
    id:29,
    title: "آگاو فیلیفرا مویی",
    desc:"کاکتوس آگاو ویکتوریا دارای برگ های ضخیم با طرح و نقش های سفید رنگ بر روی خودش است که جذابیت آن را بیشتر میکند،نگهداری آگاو ویکتوریا ساده است . این آگاو جزو آگاو های کلکسیونی به حساب می آید که رنگ ابلق آن هم دارای طرفداران زیادی است.این گیاه همانند همه آگاو ها مقاومت بالایی در برابر بیماری ها و قارچ ها دارد.آگاو ویکتوریا به راحتی قابل پرورش و نگهداری است و اگر دنبال کاکتوسی هستید که میخواهید علاوه بر زیبایی،عمر طولانی را در کنار شما داشته باشد از این آگاو تهیه کنید. از این گیاهان میتوان در گلخانه ها ،آپارتمان ها و نمایشگاه ها به عنوان گیاهان زینتی استفاده نمود",
    protaction:"برای آگاو ها از خاک کاکتوس استفاده میشود. خاکی که زهکشی بالایی داشته باشد و همینطور پیتماس،پرلیت،زغال وکوکوپیت کافی برای تغذیه گیاه در آن موجود باشد.",
    protactionT:"آگاو ها به کم آبی مقاوم هستند ولی تشنگی بیش از حد میتواند باعث پژمردگی برگ های آنها شود.آبیاری آگاو ها باید بعد از خشک شدن کامل خاک آن ها صورت بگیرد.",
    protactionS:"این گیاهان نور دوست هستند و حداقل نور پشت پنجره را برای ادامه زندگی نیاز دارند.نور فیلتر شده مستقیم میتواند کمک زیادی به پاجوش دادن این گیاه بکند.",
    protactionF:"برای آگاو ها باید از کود های نیتروژن بالا استفاده کرد تا به رشد و اضافه کردن پاجوش در این گیاه هم کمک کند.البته استفاده از کود های فسفر بالا در ریشه زایی و پتاس بالا در رشد این گیاه میتواند موثر باشد.",
    img:Fiflera,
    category: "کاکتوس"
  },
  {
    id:30,
    title: "افوربیااینرمیس",
    desc:"کاکتوس افوربیا اینرمیس ظریف و کوچک که ریشه آفریقایی دارد، یکی از گیاهان مقاوم نسبت به کم آبی است. این کاکتوس به دلیل ریشه خاصی که دارد در گلدان‌های عمیق کاشته می‌شود. کاکتوس افوربیا از ریشه خود به چندین انشعاب تقسیم می‌شود، که همین انشعابات باعث زیبایی هرچه بیشتر این کاکتوس شده است.",
    protaction:"افوربیا اینرمیس در فضای باز در صورتی که نور آفتاب شدید نباشد به خوبی رشد می‌کند. نیاز آبی این کاکتوس در فصل‌های بهار و تابستان به صورت منظم بوده و بلافاصله پس از خشک شدن خاک انجام می‌شود. اما توجه داشته باشید که در زمستان در شرایط کاملا خشک نگهداری شود.",
    protactionT:"برای طول عمر بیشتر این کاکتوس باید تعویض گلدان آن، هر دو سال یکبار انجام شود. تکثیر آن نیز از طریق بذر و قلمه امکان پذیر است. این گیاه را برای تزیین اتاق خود و یا هدیه به دوستان می‌توانید استفاده کنید.",
    img:Inermes,
    category: "کاکتوس"
  },
  {
    id:31,
    title: "کاکتوس گوش خرگوشی",
    desc:"یکی از جذاب ترین کاکتوس ها، اپونتیا میکروداسیس (Opuntia microdasys) یا گوش خرگوشی یک کاکتوس بوته ای چند ساله با برگه های بیضی یا گرد، مسطح، کوچک در حد 15 سانتی متر است. وقتی این برگه ها تازه هستند، برگه ها به صورت جفت مانند گوشهای یک خرگوش ظاهر می شوند و با بالغ شدن به صورت برگه های نرم و مخملی سبز در می آیند. آنها تیغ ندارند، اما گلوله های سفید مرتب کنار هم که هر کدام تیغ های مو مانند سفید، زرد یا قهوه ای دارند.",
    protaction:"کاکتوس خرگوشی یا کاکتوس راکتی یکی از گیاهان محبوب با نگهداری کم در آب و هوای گرم و خشک و همچنین برای نگهداری در داخل خانه است. از تحمل خشکی بسیار خوبی برخوردار است و اغلب در بسترهای مناظر، حاشیه ها و گلدان کاشته می شود.گونه های مختلف کاکتوس خرگوشی نیاز به مراقبت کمی متفاوت دارند. اما به طور کلی، آفتاب فراوان و خاک با زهکشی خوب کلید رشد آنها است. نشستن در خاکی که بیش از حد مرطوب باشد می تواند به سرعت گیاه را با مشکل پوسیدگی ریشه از بین ببرد. در غیر این صورت، کاکتوس خرگوشی بندرت دچار حشرات یا بیماری ها می شود.",
    protactionT:"نور زیاد را برای رشد در بیشتر طول سال به جز در طول چرخه زمستان می خواهد. برای سلامتی گیاه باید در این مدت نور کامل به نور جزئی کاهش یابد.",
    protactionS:"مانند بسیاری از کاکتوس ها، این نمونه به خاک های پایه شنی ماسه ای احتیاج دارد که به راحتی آب را تخلیه میکنند. معمولاً از خاک بسته بندی شده کاکتوس موجود در فروشگاه ها استقبال میکند. برای افزایش زهکشی برای گیاه کاکتوس، به نسبت 1:1 پرلیت یا پوکه مخلوط کنید.",
    protactionF:"کاکتوس گوش خرگوشی یک گونه بیابانی است. در اولین فصلی که کاشته میشود باید به طور مرتب آب داده شود. این به آن کمک می کند تا یک سیستم ریشه ای قوی ایجاد کند. خاک باید مرطوب نگه داشته شود ، اما غرق آبی نباشد. پس از فصل اول، به آبیاری پراکنده نیاز است. در طول چرخه زمستان، کاکتوس ها را به هیچ وجه نباید آب داد، و آبیاری را در اوایل بهار از سر گرفته شود.",
    img:Khargushi,
    category: "کاکتوس"
  },
  {
    id:34,
    title: "کراسولا",
    desc:"گیاه کراسولا به گیاه یشم و کراسولا خرفه ای معروف است، بومی قاره آفریقا جنوبی و کشور موزامبیک است. Crassula ovata با برگ های گوشتی و بیضی شکل محبوب ترین گونه از گیاه کراسولا است بوده، گیاهی پرانرژی بوده و فضای خانه را مطبوع می کند. ",
    protaction:"محل نگهداری این گیاه باید نور زیاد دریافت نمایید حتی قادر است نور مستقیم را تحمل کند، توجه داشته باشید جهت انتقال به مکانی با نور مستقیم نباید به طور ناگهانی آن را انتقال داد زیرا سبب چروک شدن برگ ها می گردد و روی برگ ها نواحی سوخته ظاهر می گردد. تمایل بالایی به دریافت نور صب تا ظهر دارد.",
    protactionT:"یاز آبی کراسولا پایین است و در زمستان این نیاز کمتر می شود، زمانی که خاک آن خشک شد می توانید آبدهی انجام دهید به طور کلی ابتدا شرایط خاکی آن را بسنجید و سپس آبیاری نمایید در فصل تابستان هفته ای یکبار کفایت می کند. اگر میزان آبیاری بالا باشد و گلدان زهکش مناسب نداشته باشد برگ های کراسولا دچار ریزش خواهند شد همچنین خشکی زیاد خاک در اندازه برگ ها تاثیر گذار است بدین صورت که برگ ها ضخامت خود را از دست داده و دچار چروک می گردند.",
    protactionS:"محل نگهداری گیاه مذکور باید نور را به خوبی دریافت نماید دریافت نور مستقیم باعث می شود برگ های کراسولا اندکی رنگ آن قرمز شو",
    img:Kerasula,
    category: "کاکتوس"
  },
  {
    id:35,
    title: "آلبوکا اسپیرالیس",
    desc:" این گل زیبا که در ایران به گل فنری یا پیچی نیز معروف است، از گیاهان گلدار از خانواده مارچوبگان است. یکی از معروف‌ترین واریته‌های آن Albuca spiralis می‌باشد. به طور عمده در جنوب و شرق آفریقا پخش شده و برخی از گونه‌ها در شمال آفریقا و شبه جزیره عربستان یافت می‌شود. این گیاهان از جنس شناخته شده هستند و معمولاً به عنوان نیلوفرهای لجن از آنه یاد می‌شود. این گیاهان چند ساله در حال رشد از پیاز می‌باشند و می‌توانند به بیش از یک متر برسند. این گیاهان به طور کلی گوشتی و لعاب دار هستند. همچنین گل برخی از گونه‌های معطر هستند، به خصوص در شب.",
    protaction:"خاک های متخلخل مانند خاک ساکولنت با ۶۰ درصد پیت موس، ۳۰ درصد ماسه و ۱۰ درصد ورمی ترکیب مناسبی برای کاشت این گیاه است. خاک های سنگین و دائما خیس، باعث پوسیدگی پیاز می شوند.",
    protactionT:"باید توجه داشته باشید این گیاهان بیرونی هستند و در سرمای زمستان هم در بیرون از منزل دوام می‌آورند اما به شرطی که از بعد از تعویض خاک و استراحت دو هفته‌ای به پیاز در اواخر فصل زمستان در بیرون قرار بگیرند و به شرایط محیطی عادت کنند. خاک باید خشک شود و بعد آبیاری کنید. نور کم باعث کشیدگی و نازک شدن برگها می‌شود و نور زیاد باعث ضخیم شدن و محکم‌تر شدنان. بهترین حالت برای نگهداری در بیرون از آپارتمان در تراس یا حیاط می‌باشد که البته بهتره از سرمای شدید زمستان در امان نگه داشته شود. رطوبت مورد نیاز رو در فصل گرما باید تأمین کنید.",
    img:Albuka,
    category: "گیاه بدون گل"
  },
  {
    id:36,
    title: "ایپومیا",
    desc:"گیاه ایپومیا که به سیب‌زمینی شیرین نیز معروف است، گیاهی است که می‌توان از آن به‌راحتی در محیط زندگی و حتی باغچه نگهداری کرد. گیاه ایپومیا شاخ و برگ‌ فراوانی دارد و تنوع رنگی این گیاه آپارتمانی نیز بسیار زیاد است به همین دلیل نظر افراد زیادی را به خود جذب می‌کند.",
    protaction:"گیاه ایپومیا نسبت به کم‌آبی تا حدودی مقاوم است و رشد بسیار سریعی نیز دارد. به‌طورکلی می‌توان گفت گیاه ایپومیا در مناطقی با آب‌وهوای گرم به‌خوبی رشد می‌کند. همچنین شما می‌توانید از این گیاه برای محوطه‌سازی باغچه خود استفاده کنید، یا آن را در یک گلدان آویزی بکارید و در وسط مجموعه خود قرار دهید.",
    protactionT:"به‌طورکلی در مورد گیاه ایپومیا می‌توان گفت: این گیاه سرسبزی و طراوت را در عین سادگی برای شما به ارمغان می‌آورد. این گیاه هم بسیار مقاوم است و هم زیبایی خاصی دارد، بنابراین شما می‌توانید به‌راحتی از این گیاه زینتی در محیط زندگی خود نگهداری کنید.",
    protactionF:"گیاه ایپومیا را بهتر است هفته‌ای یک‌بار آبیاری کنید. اگر در مناطق گرمسیری زندگی می‌کنید، سطح خاک این گیاه را با انگشت خود بررسی کنید و اگر خشک بود موقع آبیاری گیاه رسیده است.  از آبیاری بیش‌ازحد گیاه سیب‌زمینی شیرین بپرهیزید زیرا این کار باعث می‌شود ریشه‌های گیاه دچار پوسیدگی شود. همچنین بهتر است آبیاری گیاه را صبح‌ها انجام دهید. زیرا اگر آبیاری در هنگام ظهر انجام شود به علت گرم بودن هوا، آب به‌سرعت تبخیر می‌شود و به ریشه‌های گیاه نمی‌رسد.",
    img:Epumia,
    category: "گیاه بدون گل"
  },
  {
    id:37,
    title: "بامبو",
    desc:"نام علمی این گیاه دراسینا ساندریانا (dracaena sanderiana) است؛ ولی با نام‌های دیگری از جمله  گیاه روبانی یا نواری (Ribbon Plant) ، خیزران خوش‌یمن و حتی گیاه خوش‌شانسی (Lucky Bamboo) نیز شناخته می‌شود. این گیاه بومی مناطقی مانند شرق آسیا، آفریقای مرکزی و کامرون بوده و  چین و تایوان نیز از تولیدکنندگان و صادر‌کنندگان برتر بامبو در جهان می‌باشند. سالیان سال است که گیاه بامبو با ظاهر جذاب و متفاوت خود توانسته محبوبیت بالایی به دست آورد، به گونه‌ای که در اغلب خانه‌ها در یک گلدان شیشه‌ای دیده می‌شود.",
    protaction:"نگهداری از شاخه‌های باطراوت بامبو کار چندان سختی نیست. اما در هر حال لازم است شرایط مناسبی برای رشد و نمو گیاه خود فراهم کنید. انجام این کار رشد بامبو را تسریع کرده و شادابی آن را همیشگی می‌کند. شما می‌توانید این گیاه را در آب و یا خاک نگهداری کنید. با این حال گیاه بامبو بسترهای خاکی را به تنگ‌های آبی ترجیح می‌دهد.بسته به اینکه شاخه‌های بامبو را در بستر خاک نگهداری می‌کنید و یا داخل تنگ قرار می‌دهید، شرایط نگهداری آن متفاوت خواهد بود.",
    protactionT:"بامبو از جمله گیاهانی است که با وجود نیاز به نور، از نور مستقیم بیزار است. برخورد مستقیم اشعه‌های خورشید با برگ‌های بامبو موجب سوختگی و قهوه‌ای شدن نوک آن‌ها می‌شود. برای جلوگیری از بروز این اتفاق بهتر است گیاه بامبو را در سایه ملایم قرار داده و نور را به شکل مدیریت شده در اختیار آن قرار دهید",
    protactionS:"در زمان نگهداری بامبو در خاک لازم است به برنامه آبیاری آن دقت کافی داشته باشید. بامبو به رطوبت بالا علاقه بسیاری دارد، اما از غرقاب شدن بیزار است. غرقاب نمودن بامبو موجب فرسودگی ریشه و از بین رفتن گیاه می‌شود. برای آبیاری لازم است منتظر خشک شدن سطح خاک بمانید. سپس حجم مناسبی از آب را به خاک زهکشی شده تزریق کنید. شاید تصور کنید با نگهداری بامبو در آب، دیگر دغدغه‌ای از بابت آبیاری نخواهید داشت. اما انجام این کار احتیاط‌های نگهداری را دوچندان می‌کند چرا که بایستی آب گلدان را هر 7 الی 10 روز یکبار تعویض کنید. زمانی که آب گیاه بامبو در مدت طولانی راکد می‌ماند، سروکله جلبک‌ها پیدا می‌شود.",
    protactionF:"در صورتی که شما از خاک برای گیاه بامبو استفاده می‌کنید نکاتی را باید رعایت کنید. مهم‌ترین نکته آن انتخاب مناسب و تعویض به موقع  خاک گلدان به صورت دوره‌ای و مرطوب نگه داشتن خاک است.خاک مناسب برای بامبو خاکی است که به اندازه مساوی و مناسب خاک برگ مرغوب، دانه‌های لیکا (یا پرلیت) و اندازه مناسب پودر قارچ‌کش برای افت کشی داشته باشد. هنگام تعویض خاک نیز بهتر است مراقب ریشه‌های خاک باشید و این کار را در محیطی انجام دهید که بسته و هوا جریان نداشته باشد.",
    img:Bamboo,
    category: "گیاه بدون گل"
  },
  {
    id:38,
    title: "برگ انجیری",
    desc:"برگ انجیری یا به قول عده ای، پنیر سوییسی، از گیاهانی است که این روزها خیلی روی بورس است و طرفداران زیادی دارد و برای دکوراسیون داخلی از ان استفاده میشود.این گیاه همراه با برگ های زیبا و چشم نوازش، اصالتا متعلق به مکزیک است. برگ انجیری گیاه مقاومی است و به نگهداری چندانی نیاز ندارد.و دارای گونه های مختلفی هست، این گیاه ریشه های بلندی دارد و برگ‌های روشن و سفید رنگ برگ انجیری توانایی زیادی در جذب نور خورشید ندارند، و گیاه باید بیشتر از سایر گیاهان برای انجام فتوسنتز تلاش کند. پس آن را در مکان های کم نور قرار ندهید. نگهداری آن ساده است و برگ‌های بسیار زیبایی هم دارد. ستون محکم گیاه باعث شده او همیشه استوار و قائم بماند.",
    protaction:"برگ انجیری می‌تواند در هر بخشی از منزل شما به خوبی رشد کند! اگرچه آن در برابر میزان مختلف نور مقاوم است، اما برای داشتن رشدی سریع‌تر و گیاهی سالم‌تر، بهتر است آن را همواره تحت تابش غیرمستقیم خورشید قرار دهید چون نور زیاد باعث زرد شدن برگ ها و در نتیجه آسیب دیدن گیاه می‌شود. نور خیلی کم هم برای آن خوب نیست، چون باعث کند شدن رشد و یا حتی متوقف شدن آن می‌شود. برگ انجیری دوست دارد در نیم سایه باشد. دمای متعادل اتاق و نور زیاد خورشید خواسته های این گیاه از شماست.",
    protactionT:"هرچه دمای محیط بیشتر باشد، نیاز برگ انجیری به آب و رطوبت بیشتر می‌شود. کمی رطوبت برای خاک گیاه لازم است، به عبارتی دیگر بهتر است بعد از خشک شدن خاک آن را آبیاری کنید تا خیس و غرقاب نشود. هر زمان که نیمه بالایی خاک خشک شد، آن را به خوبی آبیاری کنید. آبیاری را تا زمانی که آب اضافی از زیر گلدان خارج شود ادامه داده و سپس زیر گلدانی را از هرگونه آب خالی کنید و در تابستان هم میتوانید گیاه را غبار پاشی کنی",
    protactionS:"این گیاه تقریبا در هر محیطی می‌تواند رشد مناسب داشته باشد، محیط گرم و مرطوب برای این گیاه زیبا کاملا ایده آل است و در چنین شرایطی به سرعت رشد می کند. و دمای 13 درجه برای این گیاه ایده آل است. اگر می‌خواهید نگهداری از آن را به بهترین شکل ممکن انجام دهید می‌توانید هفته‌ای یکبار را مرطوب کنید. این کار را به کمک یک اسپری آب انجام دهید. بهترین زمان برای خیس کردن برگهای گیاهان، آغاز روز است. زیرا آب فرصت کافی برای تبخیر شدن را خواهد داشت و از بیمار شدن برگ جلوگیری خواهد شد. برگ انجیری را در نزدیکی در و یا وسایل حرارتی قرار ندهید. چون تغییر رطوبت هوا و تغییر دما باعث آسیب دیدن برگ انجیری شده و آن را ضعیف می‌کند. ",
    protactionF:"برای داشتن بهترین نتیجه و اگر دوست دارید همیشه شاهد برگ های تازه و شاداب باشید، در طول بهار و تابستان ماهی یکبار به گیاه خود کود بدهید. مقدار کمی کود مایع کافی است تا رشد برگ انجیری به طور واضحی بهبود پیدا کند. نباید در زمستان به گیاه کود بدهید، برگ انجیری باید در این زمان استراحت کرده و مدتی را بدون کود سپری کند.",
    img:BargAnjir,
    category: "گیاه بدون گل"
  },
  {
    id:39,
    title: "بنجامین",
    desc:"گیاه بنجامین با نام انگلیسی Ficus benjamina از خانواده موراسه و بومی جنوب، جنوب شرقی و جنگلهای استوایی آسیا به ویژه هندوستان و استرالیا است. این درختچه زیبا دارای برگ های چرمی، بیضی شکل و نوک تیز است و شاخه ها خاصیت ارتجاعی و آویزان دارند. برگ ها هنگام رویش در جواني به رنگ سبز روشن هستند و به مرور زمان به رنگ سبز تيره در مي‌آيند. نگهداری بنجامین ابلق در آپارتمان نیازمند نور نسبتا زیادی است تا برگها رنگ زیبای خود را از دست ندهند.",
    protaction:"گیاه بنجامین به نور زیاد غیر مستقیم نیازمند است. بهتر است این گیاه را در محیط دارای نور زیاد مانند پشت پنجره شرقی یا جنوبی قرار دهید تا کمبود نور باعث ریزش برگها نشود. دقت کنید که نور مستقیم آفتاب باعث سوختگی و از بین رفتن برگها می‌شود.",
    protactionT:"گیاه بنجامین در دمای ۱۵ الی ۲۵ درجه سانتی گراد می‌تواند بهترین عملکرد را داشته باشد. توجه داشته باشید که این گیاه به سرمای بیش از حد حساس است. بنابراین از قرار دادن آن در نزدیکی پنجره های دارای درز و یا وسایل سرمایشی خودداری کنید. شوک تغییرات دمایی ناگهانی نیز برای بنجامین مضر است و سبب ریزش برگهای آن می‌شود.",
    protactionS:"بنجامین گیاه رطوبت دوستی است و در صورتی که رطوبت آن کم باشد دچار ریزش برگ، خشکی و شکنندگی برگها و بیحال شدن ساقه ها می‌شود. جهت تامین رطوبت درخت بنجامین می‌توانید یک ظرف آب در نزدیکی آن قرار دهید تا با تبخیر سطحی آب، رطوبت گیاه تامین شود. بهترین روش برای تامین رطوبت بنجامین استفاده از روش جزیره است. در این روش باید زیر گلدانی گیاه را پر از آب و سنگ کنید و آن را زیر گلدان قرار دهید به طوری که انتهای گلدان با آب در تماس نباشد و روی سنگها قرار بگیرد",
    protactionF:"بنجامین گیاه رطوبت دوستی است و در صورتی که رطوبت آن کم باشد دچار ریزش برگ، خشکی و شکنندگی برگها و بیحال شدن ساقه ها می‌شود. جهت تامین رطوبت درخت بنجامین می‌توانید یک ظرف آب در نزدیکی آن قرار دهید تا با تبخیر سطحی آب، رطوبت گیاه تامین شود. بهترین روش برای تامین رطوبت بنجامین استفاده از روش جزیره است. در این روش باید زیر گلدانی گیاه را پر از آب و سنگ کنید و آن را زیر گلدان قرار دهید به طوری که انتهای گلدان با آب در تماس نباشد و روی سنگها قرار بگیرد.",
    protactionFF:"گیاه بنجامین رطوبت را دوست دارد و خاک آن باید به طور پیوسته مرطوب باشد، اما در حدی باشد که در آب غوطه ور نباشد. آبیاری گیاه بنجامین اگر بیش از حد باشد باعث می شود که برگهایش زرد شده و بیفتد و در نهایت ریشه آن دچار پوسیدگی شود. بهترین آب جهت آبیاری انواع گیاه بنجامین، آب جوشیده و بدون املاح است. می توانید به جای آب جوشیده از آبی که مدتی دپو کرده اید و کلر آن از بین رفته استفاده کنید. بهتر است جهت آبیاری بنجامین از آب ولرم استفاده کنید و مطمئن شوید که سطح رویه خاک خشک شده است، سپس اقدام به آبیاری کنید. ",
    img:Benjamin,
    category: "گیاه بدون گل"
  },
  {
    id:41,
    title: "پله موآ",
    desc:"این گیاه زیبا از خانواده دراسینا مثل گیاه بامبو یا مثل دراسینا پرچمی است. این گیاه زیبا احتیاج به نور متوسطی دارد و تقریبا به  آب زیادی نیاز دارد تشنگی می تواند باعث شود که ظاهرش پژمرده شود و برگ های آن به سمت پایین آویزان شود. زادگاه این گیاه شرق آفریقا است و به دلیل مقاومتش در برابر شرایط خشکسالی جز گیاهان مقاوم به کم آبی محسوب میشود. جالبه است بدانید که گیاه پله موآ که یک گلهای آپارتمانی محسوب می‌شود، کود گریز است در نتیجه وقتی دارید از آن نگهداری می کنید در استفاده از کود های شیمایی بسیار با وسواس عمل کنید. ",
    protaction:"برای آبیاری گیاه دراسنا پیله موآ صبر کنید تا خاک گلدان شما چند سانت خشک شود.سپس دوباره به خوبی آبیاری کنید. مرطوب نگه داشتن کمی خاک بهترین شاخص برای آبیاری صحیح است.در طول زمستان ، مانند بیشتر گیاهان ، آنها بسیار کمتر نیاز دارند.",
    protactionT:"نور مستقیم خورشید ممکن است باعث سوختن و خشک شدن برگ ها شود بنابراین این گیاه را در برابر نور مستقیم خورشید قرار ندهید.گیاه دراسنا در محیط روشن با نور مناسب به طرز عالی رشد می نند .همچنین می تونند در نور کم نیز دوام بیاورد؛ البته هر چقدر که نور کمتر باشد ،سرعت رشد آن ها آهسته تر می شود ، برگ های آن ها کوچکتر می شود و رنگ آن ها جلوه خوبی نخواهد داشت",
    protactionS:"دمای مورد نیاز برای دراسنا پیله موآ شما ۱۸ الی ۲۴ درجه سانتی گراد است.اگر محیط داخل خانه شما هوای خشکی دارد ، بهتر است که با یک اسپری آن را مرطوب کنید.",
    protactionF:"بهتر است در زمان رشد آن ( بهار تا پاییز)، ماهی یکبار کمی کود مایع رقیق شده مانند کود سه بیست به آن اضافه کنید. البته زیاده‌روی در کوددهی باعث سوختگی برگ‌ها خواهدشد پس حتما در میزان استفاده شده دقت کافی را داشته‌باشید.",
    img:Pele,
    category: "گیاه بدون گل"
  },
  {
    id:42,
    title: "دیفن کاملیا",
    desc:"دیفن باخیا کاملیا یک گیاه زینتی بسیار زیبا و محبوب است. در زبان علمی به آن Diffenbachia که از خانواده Araceae می‌باشد خوانده می‌شود. این گیاه اصلیت خود را در جنگل‌های استوایی و مناطق گرمسیری دارد.  به دلیل ظاهر زینتی و برگ‌های خاصش، به عنوان یک گیاه آپارتمانی محبوب در منازل و محل‌های کار استفاده می‌شود. مراقبت از آن نسبتاً آسان است، زیرا توانایی برخورداری از شرایط نوری متفاوت و آبیاری معمولی را دارد. بیش‌تر نمونه‌های دیفن باخیایی که در فروشگاه‌ها و گلخانه‌ها مشاهده می‌کنید، از این گونه هستن",
    protaction:"دیفن باخیا کاملیا به نقطه‌ای با نور روشن اما غیر مستقیم نیاز دارد. گیاه را در مکانی قرار دهید که نور خورشید مستقیم به آن نخورد. به طور عمومی، می‌توانید آن را در نزدیکی پنجره‌های شمالی یا شرقی قرار دهید.",
    protactionT:"دیفن باخیا کاملیا به نقطه‌ای با نور روشن اما غیر مستقیم نیاز دارد. گیاه را در مکانی قرار دهید که نور خورشید مستقیم به آن نخورد. به طور عمومی، می‌توانید آن را در نزدیکی پنجره‌های شمالی یا شرقی قرار دهید.",
    protactionS:"دیفن باخیا کاملیا نیاز به آبیاری منظم دارد. در زمستان کمتر آبیاری کنید. فصل تابستان، چند بار در هفته آبیاری کنید. در آبیاری زیاده روی نکنید. زیرا باعث پوسیدگی ریشه می‌شود. باید خاک بین دو بار آبیاری به طور معتدل خشک شود. دفعات آبیاری به اندازه گلدان، حرارت اطراف و نور گیاه بستگی دارد. معمولا هر گیاهی که برگ‌های پهنی دارد رطوبت و هوای گرم را دوست دارد.",
    protactionF:"مانند بسیاری از گیاهان آپارتمانی، گیاهان دیفن باخیا به نوعی خاک نیاز دارند که مقداری آب را در خود نگه دارد. بنابراین، ماسه درشت یا پرلیت را در خاک استاندارد گلدان مخلوط کنید تا هوادهی بیشتر شود. همچنین می‌توانید از خاک گلدان استفاده کنید که درصد پیت بالایی دارد. از گلدانی با سوراخ زهکش بالا استفاده کنید تا بتواند آب را به راحتی از خود عبور دهد و از پوسیدگی ریشه گیاه جلوگیری کند",
    img:Difen,
    category: "گیاه بدون گل"
  },
  {
    id:43,
    title: "زاموفیلیا",
    desc:"زاموفیلیا یا همان زامیفولیا جزو یکی از محبوب‌ترین گیاهان آپارتمانی شناخته شده است. این گیاه با برگ‌های براق و سبز رنگ خود ظاهری مدرن را در فضای خانه شما ایجاد می‌کند. به دلیل این موضوع اکثر طراح‌های داخلی از گلدان گیاه زاموفیلیا در خانه استفاده می‌کنند. برگ‌های گوشتی و چرمی این گیاه تا حداکثر 2 سانتی‌متر رشد می‌کنند.",
    protaction:"اغلب گیاهان آپارتمانی شرایط نگهداری آسانی دارند. با این حال در این بین گیاهانی هم هستند که به توجه و حساسیت بیشتری نیاز خواهند داشت. زاموفیلیا جز آن دسته از گیاهان مقاوم آپارتمانی به شمار می‌رود و نگهداری از آن هیچ زحمتی برای شما ندارد. در کل هر گیاهی با هر شرایط نگهداری، به بستری مناسب برای رشد احتیاج دارد؛ در غیر این صورت طول عمر آن خیلی کوتاه می‌شود.",
    protactionT:"زاموفیلیا محیط‌های گرم و معتدل را به محیط‌های سرد ترجیح می‌دهد. این گیاه گرمسیر در دما‌ی زیر 8 درجه سانتی‌گراد یا پایین‌تر دوام نمی‌آورد و رشد گیاه در این دماها کاملا متوقف می‌شود. در نتیجه با گذر زمان گیاه از بین خواهد رفت. دلیل توقف رشد گیاه این است که فعالیت گیاه در هوای خنک کاهش می‌یابد و آب کمتری مصرف می‌کند. بهترین دما برای رشد گیاه زاموفیلیا، 15 تا 24 درجه سانتی‌گراد است.",
    protactionS:"حال نوبت به عامل اصلی در رشد گیاه یا همان نور می‌رسد. گیاهان گرمسیر از میزان تابش نور در طول روز، تاثیر زیادی می‌گیرند. نکته جالب در مورد زاموفیلیا، قابلیت رشد آن در سطوح مختلف نوری است. در واقع حتی اگر گلدان گیاه در مکان‌های تاریک قرار بگیرند، باز هم می‌تواند به زندگی خود ادامه دهد؛ اما به مرور زمان برگ‌های آن به حالت باریک تغییر شکل می‌دهد. با وجود این ویژگی باز هم بهتر است که گیاه را به طور منظم در معرض تابش نور غیر‌مستقیم خورشید قرار دهید. برگ‌های گیاه زاموفیلیا گوشتی و چرمی هستند، ولی باز هم نمی‌توانند در برابر تابش نور مستقیم خورشید دوام بیاورند. ",
    protactionF:"همان‌طور که قبلا هم اشاره کردیم، زاموفیلیا گیاهی گرمسیر است و آبیاری بیش از حد، آن را نابود می‌کند. این گیاه قابلیت ذخیره آب در ریشه و ساقه‌های خود دارد. در نتیجه آب‌دهی بیش از حد پیاز و ریشه گیاه را می‌پوساند و برگ‌های آن زرد می‌شوند. به طور کلی هر زمان که خاک گیاه خشک شد (نه در حدی که ترک بردارد) باید آن را آبیاری کنید. طبیعتا در فصول سرد سال، به دلیل تابش کمتر خورشید دفعات آبیاری گیاه کاهش می‌یابد و در فصول گرم باید بر عکس زمستان دفعات آبیاری را افزایش دهید. در واقع میزان آب مورد نیاز با تابش نور رابطه مستقیم دارد",
    protactionFF:"گیاهان آپارتمانی در خاک‌های سبک بهترین رشد خود را خواهند داشت. خاک سبک دارای منافذی است که آب اضافی گیاه از آن عبور می‌کند. در این صورت دیگر ریشه‌های گیاه زاموفیلیا پوسیده نمی‌شود. ترکیب خاک معمولی به همراه خاک برگ و شن، برای پرورش این گیاه انتخاب خوبی است. همچنین می‌توانید کوکوپیت، پرلیت و شن را با هم مخلوط کنید و به عنوان خاک مناسب برای زاموفیلیا استفاده نمایید.",
    img:Zamu,
    category: "گیاه بدون گل"
  },
  {
    id:44,
    title: "آگلونما",
    desc:"آگلونما یکی از مقاومترین و پر فایده ترین گیاهان آپارتمانی است که به دلیل نگهداری آسان و شکل ظاهری برگها، طرفداران زیادی دارد. یکی از مهمترین فواید این گیاه برای سلامتی انسان بر طبق تحقیقات نظریه هوای پاک ناسا، قدرت تصفیه هوای اتاق است. این گیاه زیبا آلودگی ها و مواد شیمیایی موجود در هوا را جذب می‌کند و اکسیژن آزاد می‌کند. آگلونما انواع بسیاری دارد که در طرح ها و رنگ های مختلف در بازار وجود دارند. در این مقاله همراه ما باشید تا با انواع مختلف و شرایط نگهداری آگلونما آشنا شوید.",
    protaction:"اگلونما نیاز نوری کمی دارد و در بین گیاهانی که تقریبا بدون نور زنده می‌مانند، در رتبه‌های اول قرار دارد. بهترین مکان برای آن، جایی در پشت پنجره شمالی با نور غیر مستقیم است. توجه کنید که این گیاه را در معرض نور مستقیم قرار ندهید، چرا که برگهای آن دچار آسیب می‌شوند. همواره این نکته را در نظر داشته باشید که گونه های رنگی به نور بیشتری نیاز دارند و کمبود نور باعث از بین رفتن زیبایی آن ها می شود.",
    protactionT:"گونه های مختلف این گیاه به آبیاری نسبتاً مشابهی نیاز دارند و باید زمانی اقدام به آبیاری کنید که رویه‌ی خاک کاملا خشک شده باشد. آبیاری زیاد برگهای این گیاه را خراب کرده و گیاه را دچار بیماری پوسیدگی ریشه می‌کند",
    protactionS:"این گیاه به دلیل بودن در زادگاهی گرم و مرطوب، به رطوبت نسبتا بالایی نیاز دارد. با استفاده از راههای تامین رطوبت گیاهان، می‌توانید رطوبت این گیاه را تامین کنید. ایجاد جزیره و قرار دادن یک ظرف آب در کنار آن، می‌تواند برای این گیاه کافی باشد. همچنین بهتر است در تابستان حداقل روزی یکبار غبار پاشی با آب جوشیده‌ی سرد شده بر روی برگها صورت بگیرد. جالب است بدانید که علاقه این گیاه به رطوبت، آن را به یک گیاه مناسب در حمام و سرویس بهداشتی تبدیل کرده است.",
    protactionF:"دما ایده آل آگلونما 18 الی 25 درجه سانتیگراد است و این گیاه به سرما حساس است و سرمای بیش از اندازه به راحتی موجب مرگ گیاه می‌شود. باید بدانید که قرار گرفتن این گیاه در نزدیکی وسایل سرمایشی و یا گرمایشی هم منجر به از بین رفتن گیاه می‌شود.",
    protactionFF:"این نوع گیاهان نیاز شدیدی به تغذیه مصنوعی ندارند، اما برای رشد بهتر در فصول رشد یعنی بهار و تابستان، می‌توانید از کود مخصوص گیاهان آپارتمانی استفاده کنید و این کود را هر دو هفته‌ یکبار به گیاه بدهید",
    img:Aglunma,
    category: "گیاه بدون گل"
  },
  {
    id:45,
    title: "گل ناز",
    desc:"گل ناز یک گیاه زیبا و گلدار هست که بومی جنوب برزیل و آرژانتین به حساب میاد، این گیاه در بنگلادش با نام گل زمان شناخته میشه دلیل این نامگذاری هم اینه که در یک زمان بخصوص به گل میشینه و شکوفه میده. این گل دارای گونه‌های زیادی هست و جز گیاهان گوشتی ها(ساکولنت ها) محسوب میشه. رشد این گیاه زیاد هست در ابتدا این گیاه بسیار کوچک می باشد ولی در طول یک سال ۳۰ سانتی متر در صورت داشتن فراهم کردن نیازهای گل ناز به این رشد خواهد رسید. ",
    protaction:"یکی از اساس ترین نیازهای گل ناز نور هستش، این گیاه هم همانند سایر گیاهان گوشتی به نور مستقیم و محیطی کاملا روشن نیاز دارد.(حدود ۶ الی ۸ ساعت در طول روز روشنایی) کمبود نور منجر به عدم باز شدن گل‌های گیاه میشه. جاب هست بدونید که در روزهای ابری میزان شکوفایی گلهای این گیاه کاهش پیدا میکنه.",
    protactionT:"این گیاه هم مانند ساکولنت ها نیازی به آبیاری زیاد ندارد، و اگر شما آبیاری زیادی انجام بدین باعث پوسیدگی ریشه گیاه خواهید شد بین دو آبیاری حتما حتما حتما (تاکید میکنم حتما) اجازه بدهید خاک تا عمق ۳ الی ۴ سانتی متری خشک بشه.آبیاری زیاد باعث زرد شدن برگاش و سیاه شدن ریشه می شود.",
    protactionS:"یکی از نکات مهم نگهداری گل ناز دما محیط نگهداری است، چون حداقل دمایی که میتونه تحمل کنه ۲ درجه سانتی گراد هستش. توجه کنید که گل ناز به هیچ وجه سرما رو دوست نداره ،پس محیط رشد گیاه باید همیشه گرم باشه. حتی اگر در زمان تکثیر این گیاه، گیاهچه های جوان سرما ببینند، گیاهانی ضعیف که در آینده گل‌های کمی تولید می‌کنند، به وجود می‌آید.",
    protactionF:"شما می تونید از خاک و ترکیب های خاکی که برای کاکتوس استفاده میشه برای بستر گیاه استفاده کنید. و تنها نکته خیلی مهمی که در خصوص خاک مناسب گل ناز باید توجه داشته باشید اینه که زهکشی خاک خوب باشه تا آب باقی مونده از آبیاری از زیر گلدان خارج بشه. پس بهتره که شما از خاک های سبک برای این گیاه استفاده کنید و هرچقدر این خاک سنگین باشه گلدهی گل ناز رو کاهش میده",
    img:Naz,
    category: "گیاه گلدار"
  },
  {
    id:46,
    title: "گل قاشقی",
    desc:"گل قاشقی (یا پپرومیا) با نام علمیِ Peperomia magnifolia و از خانواده پیپراسه (فلفلی ها) Piperaceae، می‌باشد. این گیاه، علفی و همیشه سبز است. گل قاشقی بومی آمریکای جنوبی (برزیل)، آفریقای جنوبی، آفریقای مرکزی و به طور کلی مناطق گرمسیری است. گیاه قاشقی بسیار کم توقع بوده و از آنجا که خواستگاه آن جنگل‌های انبوه است، نور کم، سایه و کم آبی را به راحتی تحمل می‌کند. به همین دلیل می‌تواند در رده گیاهان آپارتمانی پرطرفدار قرار گیرد.",
    protaction:"نور مناسب گل برگ قاشقی : برگ قاشقی جز گل های مقاوم به نور کم است مکان اصلی رشد این گیاه در جنگل های تاریک است و به خاطر همین توان تحمل محیط های کم نور آپارتمان را داراد و از گیاهان مقاوم در سایه و نور کم به حساب می آید. نیم سایه آفتاب و نور کنار پنجره احتیاجات نوری آن را تامین می کند، احتیاج به مراقبت در برابر اشعه مستقیم آفتاب به خصوص در ظهر دارد.",
    protactionT:"حداکثر درجه حرارت 25 درجه و حداقل درجه 18 درجه است، گیاه تا 10 درجه هم مقاومت نشان می دهد. در کل به هوای خنک نیاز دارد. و در دمای پایین تر کم کم برگ ها می ریزند و گیاه از بین می رود.",
    protactionS:"آبیاری مهمترین عامل در نگهداری گل قاشقی و معمولا شکست در نگهداری آن به این قضیه مربوط است. این گیاه در برگ های خودش آب ذخیره می کند در نتیجه اجازه بدهید تا بین دو آبیاری سطح خاک خشک شود.",
    protactionF:" از خاک خاکی با زهکش خوب برای گل قاشقی استفاده کنید که به آبیاری منظم و مواد مغذی دهید.",
    img:Ghashogi,
    category: "گیاه گلدار"
  },
  {
    id:47,
    title: "گل شمعدانی",
    desc:"گل شمعدانی یکی از محبوب ترین گیاهان آپارتمانی است که به سادگی رشد می‌کند، رنگارنگ است و بوی خوبی هم دارد. ممکن است برخی این گیاه را در خارج از خانه نگهداری کنند اما بهتر است در فصول سرد به داخل خانه آورده شود. اگر در داخل خانه نور کافی به این گیاه برسد می‌تواند تمام سال گل بدهد. ژرانیوم یا پلارگونیوم یا همان شمعدانی در واقع در اوایل قرن 18 توسط تاجران هلندی از جنوب آفریقا به اروپا وارد شد. این گیاه شباهت زیادی به ژرانیوم وحشی که در اروپا رشد می‌کرد، داشت و گیاه‌شناسان به اشتباه این دو را به عنوان یک گونه شناختند.",
    protaction:"برای اینکه شمعدانی بیشترین گل ممکن را بدهد آن را در محلی قرار دهید که بین 4 تا 6 ساعت نور خورشید را دریافت کند. شمعدانی را می‌توان به عنوان یگ گیاه خانگی یا گل زینتی کاشت. در طول فصل‌های گرم سال می‌توان این گیاه را در بیرون از خانه و محلی نورگیر قرار داد.",
    protactionT:"در اواخر تابستان یا اوایل پاییز و زمانی که دمای شب به زیر 13 درجه برسد باید شمعدانی را به داخل خانه منتقل کرد. بهترین دمای شب در زمستان برای شمعدانی چیزی بین 13 الی 16 درجه است اما این گیاه میتواند بازه دمایی بین 0 الی 27 درجه را تحمل کند و زنده بماند البته رشد مطلوبی نخواهد داشت.",
    protactionS:"گل شمعدونی را در گلدانی قرار دهید که زهکشی خوبی داشته باشد تا ریشه‌ها پوسیده نشوند. از خاکی استفاده کنید که بیش از حد سنگین یا گل مانند نباشد زیرا شمعدانی از خیس بودن بدش می‌آید.",
    protactionF:"در طول فصل رشد گیاه (بهار و تابستان) هر دو هفته یک‌بار به شمعدانی کود دهید. بهتر است این کود به صورت محلول در آب باشد و هیچوقت در زمستان به شمعدانی کود ندهید زیرا گیاه در حال استراحت است.",
    img:Shamdani,
    category: "گیاه گلدار"
  },
]
export default plants
