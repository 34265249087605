import React from "react";
import { BrowserRouter as Router, Routes, Route  , Navigate } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Home from "../Pages/Home";
import Navbar from "../Components/Navbar";
import Cactous from "../Pages/Cactous";
import BigSize from "../Pages/BigSize";
import Goldar from "../Pages/Goldar";
import BedoneGol from "../Pages/BedonGol";
import Terrarium from "../Pages/Terrarium";
import All from "../Pages/allplants";
import Blogs from "../Pages/‌Blogs/Blogs";

const Layouts = () => {
  return (
    <>
    <div className='bg-regal-green min-h-[100vh]' dir="rtl">
<Navbar/>
        <Routes>
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/Goldar" element={<Goldar />} />
        <Route path="/Cactus" element={<Cactous />} />
        <Route path="/apartemani" element={<All />} />
        <Route path="/BigPlants" element={<BigSize />} />
        <Route path="/BedoneGol" element={<BedoneGol />} />
        <Route path="/Terrarium" element={<Terrarium />} />
        <Route path="/Blogs" element={<Blogs />} />
        
        </Routes>
    </div>
    </>
  );
};

export default Layouts;
