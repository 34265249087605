import { createSlice } from '@reduxjs/toolkit';
import plantsData from './assets/plantsData';

const plantsSlice = createSlice({
  name: 'plants',
  initialState: {
    items: plantsData,
  },
  reducers: {},
});

export default plantsSlice.reducer;
