import React from "react";
import ApaPlants from "../../assets/BlogApa.jpg";
import Kood from "../../assets/kood.jpg";
import lesWater from "../../assets/lessWater.jpg";
import mokammel from "../../assets/mokammel.jpg";
import PestPlants from "../../assets/pestPlants.jpg";
//
import Insta from "../../assets/insta.png";
import Tellegram from "../../assets/telegram.png";
import Whatsapp from "../../assets/whatsapp.png";
import Twitter from "../../assets/twitter.webp";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <>
      <div className="flex px-10 mt-12 max-sm:px-5 max-sm:block">
        <div className="blogbux rounded-md max-sm: z-0">
          <img
            className="w-[800px] rounded-t-2xl max-sm:w-[350px] "
            src={ApaPlants}
            alt=""
          />
          <h2 className="pt-2 px-5 text-green-800 text-xl">
            معرفی گیاهان آپارتمانی
          </h2>
          <p className="py-2 px-5 text-gray-700 text-justify w-[700px] max-sm:w-[340px]">
            وجود گیاهان آپارتمانی در فضای خانه یک راه عالی برای تقویت خلق و خو،
            افزودن رنگ به فضای داخلی شما و تقویت مهارت باغبانی است. بسیاری از
            مردم حتی از نگهداری گیاهان آپارتمانی برای تصفیه هوا لذت می‌برند. در
            ادامه با زیبا ترین گل های آپارتمانی مختص به ایران آشنا خواهید شد.
          </p>
        </div>
        <div className="sideBar mx-14">
          <ul>
            <section className="border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">دیگر صفحات</h1>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                انواع گل و گیاه آپارتمانی
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                انوه گل های بزرگ و نگهداری آنها
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان گلدار
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                خانه
              </h3>
            </section>
            <section className="border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">دسته بندی ها</h1>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                تراریوم
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گل کاکتوس
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان بدون گل
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان گل دار
              </h3>
            </section>
            <li className="mt-5 border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">
                ارتباط با ما:<span className="text-lg px-2">۰۹۲۱۱۲۹۵۲۳۹</span>
              </h1>
              <div className="icon flex mt-1">
                <Link to="https://www.instagram.com/mim_flowerr/">
                  <img className="object-contain px-1 w-9 h-7" src={Insta} alt="" />
                </Link>
                <img className="object-contain px-1 w-9 h-7" src={Tellegram} alt="" />
                <img className="object-contain px-1 w-9 h-7" src={Whatsapp} alt="" />
                <img className="object-contain px-1 w-9 h-7" src={Twitter} alt="" />
                <h2></h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-4 mt-10 px-10 mx-auto py-10 max-sm:py-1 max-sm:grid-cols-1 max-sm:px-5 max-sm: max-md:grid-cols-2">
        <div className="blogBox max-sm:mt-5 w-[350px]">
          <img className="rounded-t-md w-[350px] h-[220px]" src={Kood} alt="" />
          <h2 className="py-1 text-base text-green-700 px-3">
            🪴انواع کود های گیاهی
          </h2>
          <p className="text-gray-600 font-light text-justify w-[340px] pt-2 pb-4 px-4">
            بطور کلی، به هر نوع ماده معدنی، طبیعی، شیمیایی و یا بیولوژیک که
            دارای عناصر غذایی باشد و باعث بالا بردن حاصلخیزی خاک و افزایش عملکرد
            کیفی و کمی محصول شود، کود اطلاق می‌شود. انواع کود مخصوص گیاهان
            آپارتمانی، به سه دسته تقسیم می‌شوند.
          </p>
        </div>
        <div className="blogBox max-sm:mt-5 w-[350px]">
          <img
            className="rounded-t-md w-[350px] h-[220px]"
            src={PestPlants}
            alt=""
          />
          <h2 className="py-1 px-3 text-base text-green-700">
            🌱انواع آفت های گیاهی
          </h2>
          <p className="text-gray-600 font-light text-justify w-[340px]  pt-2 pb-4 px-4">
            یکی از مشکلات هر کشاورز، باغ‌دار و حتی کسی‌که دارای باغچه است، انواع
            آفاتی است که سلامت گیاهانش را تهدید می‌کند. با توجه به اهمیت این
            موضوع و تاثیر مخرب آن بر محصول مدنظر، شناخت آفات یکی از مهم‌ترین
            قدم‌های مبارزه با آنها به حساب می‌آید.
          </p>
        </div>
        <div className="blogBox max-sm:mt-5 w-[350px]">
          <img
            className="rounded-t-md w-[350px] h-[220px]"
            src={lesWater}
            alt=""
          />
          <h2 className="py-1 px-3 text-base text-green-700">
            🪴انواع انواع گیاهان کم آب{" "}
          </h2>
          <p className="text-gray-600 font-light text-justify w-[340px] pt-2 pb-4 px-4">
            بسیاری از افراد بعد از نگهداری از یک گیاه و از بین رفتن آن، دلسرد
            می‌شوند و ممکن است تا مدت ها، دیگر به سراغ گیاهان نروند. در صورتی که
            می‌خواهید برای اولین بار از گیاهی نگهداری کنید باید این نکته را در
            نظر بگیرید که گیاهی که انتخاب می‌کنید علاوه بر زیبایی، با شرایط خانه
            شما سازگار باشد.
          </p>
        </div>
        <div className="blogBox max-sm:my-5 w-[350px]">
          <img
            className="rounded-t-md w-[350px] h-[220px]"
            src={mokammel}
            alt=""
          />
          <h2 className="py-1 px-3 text-base text-green-700">
            {" "}
            بهترین مکمل گیاهان
          </h2>
          <p className="text-gray-600 font-light text-justify w-[340px] pt-2 pb-4 px-4">
            مکمل های رشد و کنترل کننده آفات به دلیل نسبت متعادل عناصر پرمصرف
            مورد نیاز گیاهان نظیر ازت، فسفر و پتاسیم، در تمام مراحل رشد گیاه
            قابل مصرف بوده و سبب افزایش کمی و کیفی رشد، گلدهی و شادابی گیاه می
            گردد.
          </p>
        </div>

        <div className="sideBarr mx-auto">
          <ul>
            <section className="border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">دیگر صفحات</h1>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                انواع گل و گیاه آپارتمانی
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                انوه گل های بزرگ و نگهداری آنها
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان گلدار
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                خانه
              </h3>
            </section>
            <section className="border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">دسته بندی ها</h1>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                تراریوم
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گل کاکتوس
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان بدون گل
              </h3>
              <h3 className="text-base text-gray-600 hover:text-dark-green cursor-pointer">
                گیاهان گل دار
              </h3>
            </section>
            <li className="mt-5 border-b-[1px] pb-10 border-gray-500 w-96">
              <h1 className="text-2xl text-gray-500">
                ارتباط با ما:<span className="text-lg px-2">۰۹۲۱۱۲۹۵۲۳۹</span>
              </h1>
              <div className="icon flex mt-1">
                <Link to="https://www.instagram.com/mim_flowerr/">
                  <img className="object-contain px-1 w-9 h-7" src={Insta} alt="" />
                </Link>
                <img className="object-contain px-1 w-9 h-7" src={Tellegram} alt="" />
                <img className="object-contain px-1 w-9 h-7" src={Whatsapp} alt="" />
                <img className="object-contain px-1 w-9 h-7" src={Twitter} alt="" />
                <h2></h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Blogs;
