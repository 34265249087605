import { Link } from 'react-router-dom';
import './App.css';
import Layouts from "./Layouts/Layouts"
function App() {
  return (
<>
<Layouts/>
</>
  );
}

export default App;
