import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderData from "../assets/slider";
import "./Slider.css";

const MySlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div className="overflow-x-hidden mt-24">
      <Slider {...settings}>
        {sliderData.map((slide, index) => (
          <div className="h-96  flex justify-center" key={index}>
            <div className="slider w-[320px] h-[340px] mx-auto relative">
              <img
                src={slide.img}
                className="sliderImg w-[400px] absolute h-[350px] top-[40px]"
                alt=""
              />
              <h2 className="text-center text-dark-green text-xl">
                {slide.title}
              </h2>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MySlider;
