import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group"; // import کتابخانه انیمیشن
import "./modal.css"
const Cactous = () => {
  const plants = useSelector((state) => state.plants.items);
  const [selectedPlant, setSelectedPlant] = useState(null);

  const openModal = (plant) => {
    setSelectedPlant(plant);
  };

  const closeModal = () => {
    setSelectedPlant(null);
  };

  return (
    <>
      <h1 className="mt-28 text-center text-4xl text-dark-green">گیاه سایز بزرگ</h1>
      <div className="sections mt-6 mx-32 max-sm:mx-5 max-xl:mx-10">  {plants.map((plant, index) => (
          <div className="flex justify-center" key={index}>
            {plant.category === "گیاه سایز بزرگ" && (
              <div
                className="cartDiv flex mx-4 my-4 w-80 h-80 justify-center"
                onClick={() => openModal(plant)}
              >
                <div>
                  <img
                    src={plant.img}
                    className="w-[260px] h-[250px] top-[40px] object-contain"
                    alt=""
                  />
                  <h2 className="text-center text-dark-green text-xl">
                    {plant.title}
                  </h2>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <CSSTransition
        in={selectedPlant !== null} 
        timeout={100}
        classNames="modal"
        unmountOnExit
      >
        <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="modal-content bg-white p-8 rounded-lg max-h-[89vh] w-[600px] overflow-y-auto">
            <div className="modalHead flex justify-between mx-5">
              <h2 className="text-2xl text-gray-800 mb-4">
                {selectedPlant && selectedPlant.title}
              </h2>
              <button
                className="bg-dark-green text-white px-6 rounded"
                onClick={closeModal}
              >
                بستن
              </button>
            </div>
            {selectedPlant && (
              <>
                <img
                  src={selectedPlant.img}
                  alt=""
                  className="w-64 h-64 object-contain mx-auto"
                />
                <p className="text-center">{selectedPlant.desc}</p>
                <p className="text-center">{selectedPlant.protaction}</p>
                <p className="text-center">{selectedPlant.protactionT}</p>
                <p className="text-center">{selectedPlant.protactionS}</p>
                <p className="text-center">{selectedPlant.protactionF}</p>
                <p className="text-center">{selectedPlant.protactionFF}</p>
              </>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Cactous;
