import c1 from "../assets/sensoria-removebg-preview.png"
import c2 from "../assets/Euphorbia-erytrea-Desert-Cactus-23x100cm_1200x-removebg-preview.png"
import c3 from "../assets/4e826dcf0a8f18deda0473d033be6c4f-removebg-preview.png"
import c4 from "../assets/bonsai-removebg-preview.png"
import c5 from "../assets/ficus-elastica-variegata-removebg-preview.png"
import c6 from "../assets/alocasobhgol-removebg-preview.png"
import c9 from "../assets/pachira-removebg-preview.png"
import c7 from "../assets/شفلرا-removebg-preview.png"
import c8 from "../assets/Antu-removebg-preview.png"
 const sliderData =[
    {
   id:1,
   title: "سانسوریا",
   img:c1,
   Link:""
 },
 {
    id:2,
    title: "افوربیا",
    img:c2,
    Link:""
  },
  {
    id:3,
    title: "یوکا",
    img:c3,
    Link:""
  },
  {
    id:1,
    title: "بونسای",
    img:c4,
    Link:""
  },
  {
    id:1,
    title: "فیکوس الاستیکا",
    img:c5,
    Link:""
  },
  {
    id:1,
    title: "بابا آدم",
    img:c6,
    Link:""
  },
  {
    id:1,
    title: "شفلرا",
    img:c7,
    Link:""
  },
  {
    id:1,
    title: "آنتوریوم قرمز",
    img:c8,
    Link:""
  },
  {
    id:1,
    title: "پاچیرا",
    img:c9,
    Link:""
  },
]
export default sliderData