// import React from "react";
// import { Link } from "react-router-dom";
// const Category = [
//   {
//     id: 1,
//     title: " گیاهان آپارتمانی",
//     link: "/apartemani",
//   },
//   {
//     id: 2,
//     title: "گیاه سایز بزرگ",
//     link: "/BigPlants",
//   },
//   {
//     id: 3,
//     title: "گیاه گلدار",
//     link: "/Goldar",
//   },
//   {
//     id: 4,
//     title: "تراریوم",
//     link: "/Terrarium",
//   },
//   {
//     id: 5,
//     title: "کاکتوس",
//     link: "/Cactus",
//   },
//   {
//     id: 6,
//     title: " بدون گل",
//     link: "/BedoneGol",
//   },
// ];
// const Navbar = () => {
//   return (
//     <ul
//       class="w-full flex text-center justify-center py-8 text-gray-600 font-light"
//       dir="rtl"
//     >
//       <li class="mx-5">خانه</li>
//       <li class="mx-5 dropdown">
//         دسته بندی
//         <div class="dropdown-menu">
//           <ul>
//             {Category.map((cats) => (
//               <>
//               <Link to={cats.link}>
//               <li>{cats.title}</li>
//               </Link>
//               </>
//             ))}
//           </ul>
//         </div>
//       </li>
//       <li class="mx-5">بلاگ</li>
//       <li class="mx-5">تماس با ما</li>
//       <li class="mx-5">درباره ما</li>
//     </ul>
//   );
// };

// export default Navbar;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/x.png";
import BLogo from "../assets/logo.png";
const Category = [
  {
    id: 1,
    title: "گیاهان آپارتمانی",
    link: "/apartemani",
  },
  {
    id: 2,
    title: "گیاه سایز بزرگ",
    link: "/BigPlants",
  },
  {
    id: 3,
    title: "گیاه گلدار",
    link: "/Goldar",
  },
  {
    id: 4,
    title: "تراریوم",
    link: "/Terrarium",
  },
  {
    id: 5,
    title: "کاکتوس",
    link: "/Cactus",
  },
  {
    id: 6,
    title: " بدون گل",
    link: "/BedoneGol",
  },
];

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
    <div className="flex ">
      <ul
        class="Navbar w-full flex text-center py-8 mx-8 text-gray-500 font-light"
        dir="rtl"
      >
        <Link to="/">
          <li className="mx-2">خانه</li>
        </Link>
        <li class="mx-5 dropdown">
          دسته بندی
          <div className="dropdown-menu z-10">
                <ul className=" p-3 bg-regal-green rounded-b-md">
              {Category.map((cats) => (
                <>
                  <Link to={cats.link}>
                    <li>{cats.title}</li>
                  </Link>
                </>
              ))}
            </ul>
          </div>
        </li>
        <Link to="/Blogs">
          <li class="mx-5">بلاگ</li>
        </Link>
        <li class="mx-5">تماس با ما</li>
        <li class="mx-5">درباره ما</li>
      </ul>
        <div>
            <Link to="/">
            <img className="w-44 Blogo" src={BLogo} alt="" />
            </Link>
        </div>
      </div>
      {/* mobile menu */}
      <div className="mobileMenu">
        <div className="flex justify-between items-center text-dark-blue text-2xl">
          <div className="p-2">
            {showMenu ? (
              <FaTimes onClick={toggleMenu} />
            ) : (
              <FaBars onClick={toggleMenu} />
            )}
            </div>
            <Link to="/">
            <img className="w-36" src={Logo} alt="" />
            </Link>
          </div>
        <div
          className={`transition-all duration-500 ease-in-out ${
            showMenu ? "opacity-100 scale-100" : "opacity-0 scale-0"
          }`}
        >
          <ul
            className="w-full text-right flex justify-center text-gray-600 font-light"
            dir="rtl"
          >
            <Link to="/">
              <li className="mx-2">خانه</li>
            </Link>
            <li className="mx-2 dropdown">
              دسته بندی
              <div className="dropdown-menu z-10">
                <ul className=" p-3 bg-regal-green">
                  {Category.map((cats) => (
                    <Link to={cats.link} key={cats.id}>
                      <li className="max-md:text-base">{cats.title}</li>
                    </Link>
                  ))}
                </ul>
              </div>
            </li>
            <Link to="/Blogs">
              <li className="mx-2">بلاگ</li>
            </Link>
            <Link to="/Blogs">
              <li className="mx-2">تماس با ما</li>
            </Link>
            <li className="mx-2">درباره ما</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
