import React from "react";
import Slider from "../Components/Slider";
import Cart from "../Components/Cart";

const Home = () => {
  return (
    <>
      <div className="flex justify-center w-full max-sm:block ">
        <div>
        <h1 className="mt-40 font-thin text-gray-600 text-6xl max-md:text-4xl max-md:text-center max-md:mt-16 max-lg:text-4xl"> دنیـــــــــای<span className="text-dark-green"> گل و گیاه</span></h1>
        </div>
        <div className="w-1/3 mt-28 px-10 max-md:w-full max-md:mt-8 max-lg:w-3/5">
        <h2 className=" text-2xl text-gray-500 max-md:text-xl max-md:text-center" >طراوت و شادابی را به خانه خود بیاورد</h2>
            <p className=" w-full  text-base leading-8 text-gray-500 text-justify">خوش آمدید به وبسایت ما، جایی که به شما راهنمایی می‌کنیم تا از گل و گیاهان خود بهتر مراقبت کنید. با اطلاعات جامع و مفید ما، شما می‌توانید بهترین نتیجه را برای باغبانی خانگی و گلدان‌های داخلی خودتان بدست آورید. بپیوندید تا دنیای زیبایی گیاهان را با ما کشف کنید!</p>
        </div>
      </div>
        <Slider/>
        {/* <Cart/> */}
        </>
  );
};

export default Home;
